import { Variant } from "@interfaces/Variant"
import { PropertyAnalysis } from "cs.node.utils/ItemUtils"

const it = {
    __LANGUAGE: "it",
    ACCEDI: "Accedi",
    LOGIN: 'Login',
    RICORDAMI: 'Ricordami',
    CREDENZIALI_NON_RICONOSCIUTE: 'Credenziali non riconosciute',
    CERCA: "Cerca",
    CAMBIA_PASSWORD: "Cambia password",
    SOTTOUTENTI: "Sottoutenti",
    BILLING: "Billing",
    MODULI: "Moduli",
    LOGOUT: "Logout",
    COPIA: "Copia",
    COPIATO: "Copiato!",
    OK: "Ok",
    MODIFICA: "Modifica",
    ELIMINA: "Elimina",
    VISUALIZZA: "Visualizza",
    ORDINA: "Ordina",
    AGGIUNGI: "Aggiungi",
    CARICA: "Carica",
    ANNULLA: "Annulla",
    INDIETRO: "Indietro",
    CHIUDI: "Chiudi",
    RIFIUTA: "Rifuta",
    RIPRISTINA: "Ripristina",
    RIGENERA: "Rigenera",
    NESSUNO: "Nessuno",
    SELEZIONA_TUTTI: "Seleziona tutti",
    DESELEZIONA_TUTTI: "Deseleziona tutti",
    CONFERMA: "Conferma",
    ATTENDERE: "Attendere, prego",
    ATTENZIONE: "Attenzione",
    ERRORE_GENERICO: "Si è verificato un errore, si prega di riprovare.",
    DETTAGLI_ERRORE: "Dettagli errore",
    SELEZIONA_UN_VALORE: "Seleziona un valore",
    SELEZIONA_MODALITA_CARICAMENTO: "Seleziona la modalità di caricamento:",
    VIA_FILE: "Via file",
    VIA_URL: "Via URL",
    SELEZIONA_FILE_DA_CARICARE: "Seleziona il file da caricare",
    URL_DA_CARICARE: "Url da caricare",
    SELEZIONA_UN_FILE: "Seleziona un file",
    SI: "Sì",
    NO: "No",
    TUTTI: "Tutti",
    SALES_ORDER: "Ordine di vendita",
    TRANSFER_ORDER:"Ordine di trasferimento",
    SALVA: "Salva",
    SCARICA: "Scarica",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Eliminazione in corso...",
    SALVATAGGIO_IN_CORSO: "Salvataggio in corso...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operazione avvenuta con successo",
    COPIA_VALORE: "Copia valore",
    COPIA_ID: "Copia ID",
    PROCEDI: "Procedi",
    PROSEGUI: "Prosegui",
    CAMBIA: "Cambia",
    CREA: "Crea",
    ISTRUZIONI: "Istruzioni",
    DETTAGLI: "Dettagli",
    MOSTRA: "Mostra",
    RISULTATI: "__COUNT__ risultati",
    HOME: "Home",
    INIZIA: "Inizia",
    VERIFICA: "Verifica",
    SELEZIONA_FILE: "Seleziona file",
    CLICCA_PER_COPIARE: "Clicca per copiare",
    SALE_CHANNEL:"Canale di vendita",
    SALES_CHANNEL:"Canali di vendita",
    BADGE_SHOP_PRIMARY:"Shop Primario",
    MARKUP: "Markup",
    DISCOUNT: "Discount",
    TAX_RATE: "Tax Rate",
    COUNTRY:"Nazione",
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Telefono",
        MOBILE: "Cellulare"
    },
    MENU: {
        IL_TUO_CLOUDSTORE: "Il tuo CloudStore",
        CATALOGO: "Catalogo",
        ORDINI: "Ordini",
        SPEDIZIONI: "Spedizioni",
        CLIENTI: "Clienti",
        SHOPS: "Shops",
        ASSOCIAZIONE_MAGAZZINI: "Associazione magazzini",
        MAGAZZINI: "Magazzini",
        LISTA: "Lista",
        LISTA_ARTICOLI: "Lista articoli",
        SHIPMENTS: "Spedizioni",
        LISTING: "Listing",
        ASSOCIAZIONE_ATTRIBUTI: "Associazione attributi",
        AGGIORNAMENTO_DISPONIBILITA: "Aggiornamento disponibilità",
        WHS_MOVEMENTS:"Movimenti di magazzino",
        CATEGORY:"Categorie",
        RETURNS:"Resi",
        GESTIONE_PREZZI: "Gestione prezzi",
        CONDIZIONI_COMMERCIALI: "Condizioni commerciali",
        MODIFICA_CONDIZIONE_COMMERCIALE: "Modifica condizione commerciale",
        LOGS:"Registro Attività",
        PACKAGING: "Packaging",
        STAMPA_ETICHETTE: "Stampa etichette",
        LOGS_SETTINGS:"Configurazione registro attività",
        RISERVE: "Riserve",
        QUEUES:"Code",
        REGOLE_ESCLUSIONE_LISTING: "Regole di esclusione dai listing",
        REGOLE_ESCLUSIONE: "Regole di esclusione",
        MODIFICA_REGOLA_ESCLUSIONE_LISTING: "Modifica regola di esclusione da listing",
        CUSTOMERS: "Clienti",
        MODULI: "Moduli",
    },
    PAGE_ACCESS_DENIED: {
        TITLE:"Accesso non consentito",
        SUBTITLE:"Non è possibile accedere alla risorsa richiesta.",
        BACK_TO_HOME:"Torna alla home"
    },
    PAGE_MAINTENANCE: {
        TITLE: "Manutenzione in corso",
        SUBTITLE: "Stiamo eseguendo delle operazioni di manutenzione, ma torneremo presto online."
    },
    PAGE_HOME: {
        BENVENUTO: (name: string)=><>Benvenuto, {name}</>,
        RIEPILOGO: "Ecco un riepilogo della tua attività.",
    },
    PAGE_BUSINESS_CONDITIONS: {
        NUOVA_CONDIZIONE_COMMERCIALE: "nuova condizione commerciale",
        AVVIA_ELABORAZIONE: "Avvia elaborazione",
        TEST_CONDIZIONI_COMMERCIALI: "Esegui un test delle condizioni commerciali",
        TUTTE_LE_CONDIZIONI_COMMERCIALI_APPLICATE: "Tutte le condizioni commerciali sono applicate.",
        DA_ELABORARE: "Ci sono __ELEMENTS__ da elaborare.",
        ASSOCIAZIONI_CON_SHOPS: "associazioni con shops",
        ASSOCIAZIONI_CON_CUSTOMER: "associazioni con customers",
        CONDIZIONI: "condizioni",
        CONDIZIONI_ATTIVE: "Condizioni attive",
        CONDIZIONI_DA_ELABORARE: "Condizioni da elaborare",
        CONDIZIONI_DISABILITATE: "Condizioni disabilitate",
        CONDIZIONI_CANCELLATE: "Condizioni cancellate",
        CONDIZIONI_IN_ELABORAZIONE: "Condizioni in elaborazione",
        TAB_0: "Condizioni attive / da elaborare / disabilitate",
        CONFERMA_DISABILITA: "Confermi la disattivazione della condizione __NAME__?",
        CONFERMA_CANCELLAZIONE: "Confermi la cancellazione della condizione __NAME__?",
        CONFERMA_ABILITA: "Confermi l'abilitazione della condizione __NAME__?",
        CONFERMA_ANNULLAMENTO: "Confermi l'annullamento dell'operazione in corso per la condizione __NAME__?",
        CONFERMA_AVVIO_ELABORAZIONE: "Confermi l'avvio dell'elaborazione delle condizioni commerciali?",
        DATI_GENERALI: "Dati generali",
        NOME: "Nome",
        TAX_RATE: "Tax Rate",
        CONDIZIONE_DI_DEFAULT: "Condizione di default",
        ASSOCIAZIONE_SHOP: "Associazione shop",
        ASSOCIAZIONE_CUSTOMER: "Associazione customer",
        ECCEZIONI: "Eccezioni",
        ASSOCIAZIONE_SHOP_INFO: "In questa sezione puoi associare questa condizione commerciale a uno o più shop.",
        AGGIUNGI_ASSOCIAZIONE: "Aggiungi associazione",
        NUOVA_ASSOCIAZIONE: "Nuova associazione",
        CONFERMA_ASSOCIAZIONE_SHOP: "Confermi l'associazione di questa condizione commerciale agli shop selezionati?",
        CONFERMA_CANCELLAZIONE_ASSOCIAZIONE_SHOP: "Confermi la cancellazione dell'associazione selezionata?",
        ASSOCIAZIONE_CUSTOMER_INFO: "In questa sezione puoi associare questa condizione commerciale a uno o più customer.",
        CONFERMA_ASSOCIAZIONE_CUSTOMER: "Confermi l'associazione di questa condizione commerciale ai customer selezionati?",
        CONFERMA_CANCELLAZIONE_ASSOCIAZIONE_CUSTOMER: "Confermi la cancellazione dell'associazione selezionata?",
        SELEZIONA_UN_CUSTOMER: "Seleziona un customer",
        ECCEZIONI_INFO: "In questa sezione puoi definire delle eccezioni alla condizione di default definita in testata.",
        NUOVA_ECCEZIONE: "Nuova eccezione",
        MODIFICA_ECCEZIONE: "Modifica eccezione",
        SCARICA_TEMPLATE: "Scarica template Excel vuoto",
        SCARICA_ECCEZIONI: "Scarica Excel delle eccezioni configurate",
        CARICA_ECCEZIONI: "Carica Excel delle eccezioni per questa condizione commerciale",
        SELEZIONA_ALMENO_UN_ATTRIBUTO: "Seleziona almeno un attributo su cui applicare la condizione",
        CONFERMA_SALVATAGGIO_CONDIZIONE: "Confermi il salvataggio delle modifiche effettuate?",
        LOGS:"Registro Attività",
        TABLE: {
            NOME: "Nome",
            VARIAZIONE: "Variazione",
            MARKUP: "Markup",
            DISCOUNT: "Sconto",
            NUMERO_ECCEZIONI: "N° Eccezioni",
            STATO: "Stato",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            DISABILITA: "Disabilita",
            CANCELLA: "Cancella",
            RIATTIVA: "Riattiva",
            ANNULLA_OPERAZIONE: "Annulla operazione",
        },
        STATUS: {
            LABEL: {
                PENDING: "Da elaborare",
                PROCESSING: "In elaborazione",
                CONFIRMED: "Confermata",
                TO_BE_DEPRECATED: "Da rendere obsoleta",
                DEPRECATED: "Obsoleta",
                TO_BE_DISABLED: "Da disabilitare",
                DISABLED: "Disabilitata",
                TO_BE_CANCELED: "Da cancellare",
                CANCELED: "Cancellata",
                TO_BE_ENABLED: "Da abilitare",
                    },
            SHORT: {
                PENDING: "Questa condizione è in attesa di essere confermata e attivata.",
                PROCESSING: "Questa condizione è attualmente in fase di elaborazione.",
                CONFIRMED: "Questa condizione è confermata e attualmente in vigore.",
                TO_BE_DEPRECATED: "Questa condizione è stata impostata per essere resa obsoleta durante la prossima elaborazione.",
                DEPRECATED: "Questa condizione è obsoleta e non è più in vigore.",
                TO_BE_DISABLED: "Questa condizione è stata impostata per essere disabilitata durante la prossima elaborazione.",
                DISABLED: "Questa condizione è attualmente disabilitata e non viene applicata.",
                TO_BE_CANCELED: "Questa condizione è stata impostata per essere cancellata durante la prossima elaborazione.",
                CANCELED: "Questa condizione è cancellata.",
                TO_BE_ENABLED: "Questa condizione è stata impostata per essere abilitata durante la prossima elaborazione.",
            },
            EXTENDED: {
                PENDING: "Questa condizione è in attesa di essere confermata e attivata.",
                PROCESSING: "Questa condizione è attualmente in fase di elaborazione.",
                CONFIRMED: "Questa condizione è confermata e attualmente in vigore.",
                TO_BE_DEPRECATED: "Questa condizione è stata impostata per essere resa obsoleta durante la prossima elaborazione.",
                DEPRECATED: "Questa condizione è obsoleta e non è più in vigore.",
                TO_BE_DISABLED: "Questa condizione è stata impostata per essere disabilitata durante la prossima elaborazione.",
                DISABLED: "Questa condizione è attualmente disabilitata e non viene applicata.",
                TO_BE_CANCELED: "Questa condizione è stata impostata per essere cancellata durante la prossima elaborazione.",
                CANCELED: "Questa condizione è cancellata.",
                TO_BE_ENABLED: "Questa condizione è stata impostata per essere abilitata durante la prossima elaborazione.",
            }
        },
        EXCEPTIONS: {
            SEASON: "Season",
            BRAND: "Brand",
            CATEGORY: "Category",
            SKU_PARENT: "SKU Parent",
            PARTNER_CODE: "Partner",
            VARIAZIONE: "Variazione",
            AZIONI: "Azioni"
        },
        PROCESSING: {
            ELABORAZIONE_IN_CORSO: "Elaborazione in corso...",
            PREPARAZIONE: "Preparazione...",
            ELABORAZIONE_ASSOCIAZIONI_CUSTOMERS: "Elaborazione associazioni customer...",
            ELABORAZIONE_ASSOCIAZIONI_SHOPS: "Elaborazione associazioni shop...",
            AGGIORNAMENTO_STATI_RELAZIONI: "Aggiornamento stati e relazioni...",
            APPLICAZIONE_CONDIZIONI_CREAZIONE_LISTING: "Applicazione condizioni e creazione listing..."
        },
        UPLOAD_RESULTS: {
            RISULTATO_CARICAMENTO: "Risultato del caricamento",
            NO_ROWS_ACCEPTED: "Poiché nessuna riga è stata elaborata correttamente, non sono state apportate modifiche alle eccezioni attuali.",
            SOME_ROWS_ACCEPTED: "Puoi visionare il risultato nella tabella delle eccezioni in questa pagina; è possibile che alcune righe dell'Excel siano state raggruppate per essere gestite con maggior semplicità e chiarezza. Se sei soddisfatto del risultato, ti basterà salvare la condizione commerciale con l'apposito pulsante in alto a destra!",
            EXCEPTIONS_CREATED: "Eccezioni create:",
            ROWS_ERROR: "Righe in errore (scartate):",
            ROWS_WARNING: "Righe con avvisi:",
            RIGA: "Riga",
        }
    },
    PAGE_LISTING_EXCLUSION_RULES: {
        STATUS: {
            LABEL: {
                PENDING: "Da elaborare",
                PROCESSING: "In elaborazione",
                CONFIRMED: "Confermata",
                TO_BE_DEPRECATED: "Da rendere obsoleta",
                DEPRECATED: "Obsoleta",
                TO_BE_DISABLED: "Da disabilitare",
                DISABLED: "Disabilitata",
                TO_BE_CANCELED: "Da cancellare",
                CANCELED: "Cancellata",
                TO_BE_ENABLED: "Da abilitare",
                    },
            SHORT: {
                PENDING: "Questa regola è in attesa di essere confermata e attivata.",
                PROCESSING: "Questa regola è attualmente in fase di elaborazione.",
                CONFIRMED: "Questa regola è confermata e attualmente in vigore.",
                TO_BE_DEPRECATED: "Questa regola è stata impostata per essere resa obsoleta durante la prossima elaborazione.",
                DEPRECATED: "Questa regola è obsoleta e non è più in vigore.",
                TO_BE_DISABLED: "Questa regola è stata impostata per essere disabilitata durante la prossima elaborazione.",
                DISABLED: "Questa regola è attualmente disabilitata e non viene applicata.",
                TO_BE_CANCELED: "Questa regola è stata impostata per essere cancellata durante la prossima elaborazione.",
                CANCELED: "Questa regola è cancellata.",
                TO_BE_ENABLED: "Questa regola è stata impostata per essere abilitata durante la prossima elaborazione.",
            },
            EXTENDED: {
                PENDING: "LONG DESCRIPTION PENDING",
                PROCESSING: "LONG DESCRIPTION PROCESSING",
                CONFIRMED: "LONG DESCRIPTION CONFIRMED",
                TO_BE_DEPRECATED: "LONG DESCRIPTION TO_BE_DEPRECATED",
                DEPRECATED: "LONG DESCRIPTION DEPRECATED",
                TO_BE_DISABLED: "LONG DESCRIPTION TO_BE_DISABLED",
                DISABLED: "LONG DESCRIPTION DISABLED",
                TO_BE_CANCELED: "LONG DESCRIPTION TO_BE_CANCELED",
                CANCELED: "LONG DESCRIPTION CANCELED",
                TO_BE_ENABLED: "LONG DESCRIPTION TO_BE_ENABLED",
            }
        },
        PROCESSING: {
            ELABORAZIONE_IN_CORSO: "Elaborazione in corso...",
            PREPARAZIONE: "Preparazione delle regole in corso...",
            ELABORAZIONE: "Elaborazione delle regole in corso...",
            APPLICAZIONE: "Applicazione delle regole in corso...",
            FINALIZZAZIONE: "Impostazione stati finali e creazione dei listing in corso..."
        },
        TABLE: {
            NOME: "Nome regola",
            DATA_CREAZIONE: "Data creazione",
            DATA_MODIFICA: "Data modifica",
            STATO: "Stato",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            DISABILITA: "Disabilita",
            CANCELLA: "Cancella",
            RIATTIVA: "Riattiva",
            ANNULLA_OPERAZIONE: "Annulla operazione",
        },
        TUTTE_LE_REGOLE_APPLICATE: "Tutte le regole sono applicate.",
        DA_ELABORARE: "Ci sono __COUNT__ regole da elaborare.",
        REL_NON_COMPLETA: "Questa regola non verrà applicata in quanto non sono state inserite le condizioni o non è stata specificata la lista degli shop esclusi",
        CONFERMA_AVVIO_ELABORAZIONE: "Confermi l'avvio dell'elaborazione delle regole?",
        NUOVA_REGOLA: "Nuova regola",
        AVVIA_ELABORAZIONE: "Avvia elaborazione delle regole",
        TEST_REGOLE: "Esegui un test sulle regole",
        REGOLE_ATTIVE: "Regole attive",
        REGOLE_DA_ELABORARE: "Regole da elaborare",
        REGOLE_DISABILITATE: "Regole disabilitate",
        REGOLE_CANCELLATE: "Regole cancellate",
        REGOLE_IN_ELABORAZIONE: "Regole in elaborazione",
        TAB_0: "Regole attive / da elaborare / disabilitate",
        CONFERMA_DISABILITA: "Confermi la disattivazione della regola __NAME__?",
        CONFERMA_CANCELLAZIONE: "Confermi la cancellazione della regola __NAME__?",
        CONFERMA_ABILITA: "Confermi l'abilitazione della regola __NAME__?",
        CONFERMA_ANNULLAMENTO: "Confermi l'annullamento dell'operazione in corso per la regola __NAME__?",
        NOME: "Nome",
        CONFERMA_SALVATAGGIO_REGOLA: "Confermi il salvataggio delle modifiche effettuate alla regola?",
        DATI_GENERALI: "Dati generali",
        CONDIZIONI: "Condizioni",
        SHOP_ESCLUSI: "Shop esclusi",
        STORICO: "Storico",
        HISTORY: {
            NESSUN_ELEMENTO_DISPONIBILE: "Nessun elemento disponibile.",
            CREATE_GUI: "La regola è stata creata sull'applicazione CloudStore.",
            CREATE_ENGINE: "La regola è stata creata dal motore di regole CloudStore durante il passaggio:",
            CREATE_API: "La regola è stata creata dalle API CloudStore.",
            UPDATED_GUI: "La regola è stata modificata sull'applicazione CloudStore.",
            UPDATED_ENGINE: "La regola è stata modificata dal motore di regole CloudStore durante il passaggio:",
            UPDATED_API: "La regola è stata modificata dalle API CloudStore.",
            STEPS: {
                _0: "sconosciuto",
                _1: "preparazione delle regole",
                _2: "elaborazione delle regole",
                _3: "applicazione delle regole",
                _4: "impostazione stati finali e creazione dei listing",
            }
        },
        EXCLUDED_SHOPS_INFO: "In questa sezione puoi scegliere a quali shop applicare l'esclusione dai listing definita nella regola che stai modificando.",
        SELEZIONA_SHOP_ESCLUSI: "Seleziona gli shop da escludere",
        NUOVA_CONDIZIONE: "Nuova condizione",
        MODIFICA_CONDIZIONE: "Modifica condizione",
        SCARICA_TEMPLATE: "Scarica template Excel vuoto",
        SCARICA_CONDIZIONI: "Scarica Excel delle condizioni configurate",
        CARICA_CONDIZIONI: "Carica Excel delle condizioni per questa regola",
        CONDIZIONI_INFO: "In questa sezione puoi definire le condizioni affinché la regola di esclusione risulti valida.",
        CONDITIONS: {
            SEASON: "Season",
            BRAND: "Brand",
            CATEGORY: "Category",
            SKU_PARENT: "SKU Parent",
            OPERATORE: "Operatore",
            VALORE: "Valore",
            CONFERMA_AGGIUNGI_A_CONDIZIONE: "Confermi l'aggiunta dei valori richiesti alla condizione?",
            CONFERMA_AGGIUNGI_A_CONDIZIONE_HELP: "L'aggiunta non verrà salvata in automatico! Potrai sempre decidere di modificare le condizioni prima del salvataggio.",
            VALORE_PRESENTE_IN_CLOUDSTORE: "Questo valore è già presente in qualcuno degli articoli del tuo inventario.",
            VALORE_NON_PRESENTE_IN_CLOUDSTORE: "Questo valore non è ancora censito in nessuno degli articoli del tuo inventario.",
            NESSUN_VALORE_SELEZIONATO: "Nessun valore selezionato.",
            CATEGORIE_SELEZIONATE: "Ci sono __COUNT__ categorie selezionate. Per visualizzarle, utilizzare il selettore qui sopra.",
        },
        UPLOAD_RESULTS: {
            RISULTATO_CARICAMENTO: "Risultato del caricamento",
            NO_ROWS_ACCEPTED: "Poichè nessuna condizione caricata è risultata valida, le condizioni attuali rimangono immutate.",
            SOME_ROWS_ACCEPTED: "Puoi visualizzare il risultato del caricamento nei relativi box di questa pagina.",
        }
    },
    PAGE_MODULES: {
        STATUS:{
            LABEL: "Stato",
            ACTIVE: "Attivo",
            INACTIVE: "Non attivo",
            NOT_APPLICABLE: "Non Applicabile",
            PENDING: "In Attesa",
            VALIDATING: "In Validazione",
            CONFIRMED: "Confermato",
            AUTH_ERROR: "Errore di Autenticazione",
            ERROR: "Errore",
        },
        TITLES: {
            BUSINESS_CONDITIONS: "Condizioni Commerciali",
            B2B: "B2B",
            POS: "POS",
            ERP: "ERP",
            FINANCE: "Finance",
            FxFF: "Fullfilment by Farfetch",
            WAREHOUSES: "Magazzini",
            NETSUITE: "Integrazione NetSuite",
            MARKETPLACE_SAVAIT: "Marketplace Savait",
            DETAILS_TITLE:"Dettaglio modulo"
        },
        TITLES_DETAILS: {
            BUSINESSCONDITIONS:"Modulo Condizioni Commerciali",
            B2B: " Modulo B2B",
            POS: " Modulo POS",
            ERP: " Modulo ERP",
            FINANCE: "Modulo Finance",
            FXFF: "Modulo Fullfilment by Farfetch",
            WAREHOUSES: "Modulo Magazzini",
            NETSUITE: "Integrazione NetSuite",
            MARKETPLACESAVAIT: "Integrazione Marketplace Savait",
            MARKETPLACE_SAVAIT: "Integrazione Marketplace Savait",
        },
        DESCRIPTIONS: {
            BUSINESS_CONDITIONS: "Questo modulo permette di gestire in maniera completa le informazioni dei prezzi di un articolo sia a livello di listini che a livello di condizioni commerciali.",
            B2B: "È un'applicazione che consente di vendere i prodotti del tuo catalogo a clienti B2B attraverso le funzionalità tipiche di un canale di vendita, come la gestione dell'anagrafica clienti e la gestione del flusso ordini. Compatibile con PC e tablet.",
            POS: "È un'applicazione dedicata al mongo Retail che consente di integrare un negozio fisico nell'ecosistema di CloudStore attraverso semplici funzionalità come la creazione di un ordine e la gestione dell'anagrafica di un cliente. Permette inoltre di avere monitorare le giacenze presente in altri negozi/magazzini così da poter fornire al cliente finale un'esperienza completa.",
            ERP: "Il modulo ERP è progettato per fornire funzionalità specifiche e dedicate a chi integra il proprio ERP con CloudStore attraverso le CloudStore API",
            FINANCE: "Questo modulo permette di stimare le componenti tasse sui prezzi indicati negli ordini privi di tale informazione, durante la fase di ricezione ordine dagli shop.",
            FxFF: "L'integrazione permette la gestione dei canali Farfetch per i quali è attivo il servizio di Fulfillment by Farfetch (FxFF)",
            WAREHOUSES: "Il modulo magazzini mette a disposizione diverse funzionalità e aree che l'utente può usare per gestire in maniera più ampia e personalizzata l'inventario e la pubblicazione degli articoli sugli shop.",
            NETSUITE: "L'integrazione implementa processi di inbound e outbound di dati tra gli account di CloudStore e di NetSuite al fine di sincronizzare clienti, articoli, ordini, dati di fatturazione, dati di spedizione e movimenti di magazzino.",
            MARKETPLACE_SAVAIT: "Questo modulo abilita un account CloudStore alle funzionalità tipiche di un Marketplace quali la gestione del catalogo da fornitori multipli e lo smistamento degli ordini ai vari partner",
        },
        DESCRIPTIONS_LONG : {
            BUSINESSCONDITIONS: "Questo modulo permette di gestire in maniera completa le informazioni dei prezzi di un articolo sia a livello di listini che a livello di condizioni commerciali. È quindi possibile gestire differenti listini da associare a Shop per definire prezzi di vendita e prezzi scontati di ogni articolo così come è possibile definire delle condizioni commerciali da riservare sia a Shop (sia che essi rappresentino dei rivenditori o meno) sia a Customer che acquisteranno tramite l'applicazione B2B di CloudStore.",
            B2B: "È un'applicazione che consente di vendere i prodotti del tuo catalogo a clienti B2B attraverso le funzionalità tipiche di un canale di vendita, come la gestione dell'anagrafica clienti e la gestione del flusso ordini. Compatibile con PC e tablet. Creazione ordini Il modulo offre la possibilità di creare ordini da catalogo, aggiungendo articoli al carrello. Molto interessante la funzionalità Ordini da Showroom che ti permette di creare un ordine durante una visita guidata nel tuo showroom con un'interfaccia appositamente ottimizzata per dispositivi mobile. Preordini Dai la possibilità ai tuoi clienti di creare pre-ordini che tu potrai integrare e confermare, specificandone scontistiche e quantità. Scheda articolo La scheda articolo è stata progettata per raggruppare le varizioni di un articolo specificandone l'attributo di variazione, così da facilitare la consultazione delle quantità disponibili per ogni variazione. Ordine veloce Con un solo click, tutte le quantità disponibili di tutte le variazioni a catalogo di un articolo verranno aggiunte all'ordine. Anagrafica clienti Gestisci in maniera autonoma l'anagrafica dei tuoi clienti dando loro accesso al portale tramite username e password.",
            POS: "È un'applicazione dedicata al mongo Retail che consente di integrare un negozio fisico nell'ecosistema di CloudStore attraverso semplici funzionalità come la creazione di un ordine e la gestione dell'anagrafica di un cliente. Permette inoltre di avere monitorare le giacenze presente in altri negozi/magazzini così da poter fornire al cliente finale un'esperienza completa.",
            ERP: "Il modulo ERP è progettato per fornire funzionalità specifiche e dedicate a chi integra il proprio ERP con CloudStore attraverso le CloudStore API. Implementa logiche legate all’aggiornamento delle quantità, agevola il recupero di informazioni quali i movimenti di magazzino relativi agli ordini, predispone code dedicate alla comunicazione di specifiche informazioni (come gli ordini per esempio) perché si possa definire un flusso più puntuale e una relazione più completa tra ERP e CloudStore",
            FINANCE: "Questo modulo permette di stimare le componenti tasse sui prezzi indicati negli ordini privi di tale informazione, durante la fase di ricezione ordine dagli shop. La stima viene effettuata sulla base di alcuni parametri definiti dall’utente, in particolare le percentuali tasse e le classi di tassazione. Attraverso le percentuali tasse vengono definite le percentuali tasse da applicare al verificarsi di determinate condizioni, riconducibili alla localizzazione geografica dell’indirizzo di spedizione dell’ordine. Attraverso le classi di tassazione vengono definite delle classi di tassazione a cui vengono associate le percentuali tasse. è necessario specificare per ogni prodotto in inventario, la classe tassazione da applicare, al fine di permettere il calcolo della stima.",
            FXFF: "L'integrazione permette la gestione dei canali Farfetch per i quali è attivo il servizio di Fulfillment by Farfetch (FxFF). In fase di creazione di un canale di tipo Farfetch, è possibile definire se il servizo FxFF è attivo e in quel caso è necessario specificare il magazzino di riferimento.",
            WAREHOUSES: "Il modulo magazzini mette a disposizione diverse funzionalità e aree che l'utente può usare per gestire in maniera più ampia e personalizzata l'inventario e la pubblicazione degli articoli sugli shop. Anagrafica magazzini Elenco dei magazzini utilizzati per i quali è possibile anche specificare un codice clente e una priorità. Strategia Sono a disposizione differenti strategie che permettono a CloudStore di decidere (o meno) il magazzino di picking della merce a seguito dell'arrivo di un ordine. Funzionalità avanzate Sarà possibile abilitare/disabilitare magazzini sui singoli shop e/o abilitare/disabilitare i magazzini sui singoli articoli. In questo modo sarà possibile personalizzare la disponibilità effettiva che si vuole pubblicare su uno specifico Shop.",
            NETSUITE: "Questa integrazione, attivabile opzionalmente nel tuo account CloudStore, consente di attivare la comunicazione di CloudStore con il tuo account Oracle NetSuite. L'integrazione implementa processi di inbound e outbound di dati tra i due account al fine di sincronizzare clienti, articoli, ordini, dati di fatturazione, dati di spedizione e movimenti di magazzino. Per diverse entità è supportata la sincronizzazione nei due sensi, in base a quale dei due sistemi è il master di quella specifica informazione. La comunicazione avviene attraverso code di attività, elaborate dall'engine di CloudStore e consultabili e verificabili tramite interfaccia utente, attraverso la quale l'operatore può rielaborare comunicazioni non andate a buon fine. CloudStore interagisce con NetSuite attraverso le sue API, operando dove possibile direttamente con le CRUD delle entità coinvolte, oppure ottenendo informazioni attraverso ricerche salvate. Per il corretto funzionamento dell'integrazione e per gestire un insieme informativo più ricco di quello base presente in NetSuite, l'integrazione utilizza Custom Field, Custom Record e Custom List che dovranno essere configurati in NetSuite (la configurazione di questi elementi nell'account NetSuite è affidata al partner tecnologico Oracle del Cliente). L'integrazione è ampiamente personalizzabile da parte del Team CloudStore attraverso un ricco insieme di parametri di configurazione e attraverso codice custom. Al fine di ottimizzare le performance, i processi dell'integrazione sono tendenzialmente eseguiti in parallelo.",
            MARKETPLACESAVAIT: "Questo modulo abilita un account CloudStore alle funzionalità tipiche di un Marketplace quali la gestione del catalogo da fornitori multipli e lo smistamento degli ordini ai vari partner",
        },
        B2B: {
            SHOP_PRIMARIO: "Shop primario",
            SHOP_SECONDARI: "Numero shop secondari",
        },
        POS: {
            NUMERO_SHOP: "Numero shop",
            SHOP_ASSOCIATIONS:"Associazione shop"
        },
        WAREHOUSES: {
            ALGO_DESC:{
                NO_OP:"all'arrivo di un ordine, CloudStore non esegue nessuna operazione di scelta del magazzino di picking della merce. Le quantità dei magazzini rimarrano quindi invariate. Viene aggiornata solamente la quantità del bucket.",
                WHS_PRIORITY:"all'arrivo di un ordine, CloudStore sceglierà il magazzino di picking di ogni articolo presente nell'ordine stesso sulla base delle priorità dei magazzini impostate dall'utente. Verrà scelto il magazzino con il valore di priorità più basso. ES: magazzini con priorità 1 è più prioritario del magazzino con priorità 2. Viene aggiornata anche la quantità del bucket.",
                TEMP_COMMIT:"all'arrivo di un ordine, CloudStore aggiunge nel magazzino virtuale TEMPORARY COMMIT gli articoli e le quantità presenti nell'ordine. Il TEMPORARY COMMIT è tenuto in considerazione nel calcolo delle quantità da inviare al canale. Viene aggiornata anche la quantità del bucket.",
            },
            ALGORITMO: "Algoritmo",
            SELECT_ALGO: "Seleziona un algoritmo per il modulo magazzini",
            NONE :"NONE",
            NO_OP:"Nessuna operazione",
            WHS_PRIORITY:"Priorità Magazzini",
            TEMP_COMMIT:"Magazzino di appoggio",
            SHOP_DRIVEN:"SHOP DRIVEN",
        },
        NETSUITE: {
            STATO: "Stato",
        },
        ACTIVATE_MODULE:"Attivazione Modulo",
        UPDATE_DATE:"Data aggiornamento",
        ENABLE_DISABLED:"Abilita / disabilita modulo",
        GENERAL_CONFIG: "Configurazioni generali",
        UPDATE_CONFIRM:"Si vuole prosegure con l'aggiornamento del modulo __MODULE__",
        ERROR_LOADING:"Si è verificato un errore durante il caricamento del modulo, si prega di riprovare o di contattare l'assistenza CloudStore",
    },
    PAGE_PACKAGING: {
        SELEZIONA_UN_ORDINE: "Seleziona un ordine",
        SELEZIONA_UN_MAGAZZINO: "Seleziona un magazzino...",
        SELEZIONA_UN_COMPUTER: "Seleziona un computer di stampa...",
        SELEZIONA_UNA_STAMPANTE_ZEBRA: "Seleziona una stampante zebra...",
        SELEZIONA_UNA_STAMPANTE_STANDARD: "Seleziona una stampante standard...",
        SELEZIONA_UNA_STAMPANTE_DI_SERVIZIO: "Seleziona una stampante di servizio...",
        SELEZIONA_UN_MAGAZZINO_INFO: "Per iniziare, seleziona un magazzino nelle opzioni in alto a destra.",
        LEGGI_UN_ARTICOLO: "Leggi il codice a barre di un articolo per identificare un ordine da lavorare.",
        ARTICOLO_NON_IN_ORDINE: "L’articolo letto non appartiene a quest’ordine.",
        ARTICOLO_COMPLETATO: "L'articolo letto è già stato completamente confermato in quest'ordine.",
        ARTICOLO_IN_NESSUN_ORDINE: "Non è stato possibile trovare ordini da inscatolare che contengono questo articolo.",
        ARTICOLO_IN_ORDINE_PACKAGED: "L’articolo appartiene a un ordine che è già stato confermato ma non stampato. Vuoi andare alla pagina di dettaglio dell’ordine per procedere con la stampa?",
        ARTICOLO: "Articolo",
        MAGAZZINO: "Magazzino",
        SCATOLA: "Scatola",
        SELEZIONA_UNA_SCATOLA_ORDINE: "Seleziona una scatola per l'ordine",
        SELEZIONA_UNA_SCATOLA_RIGHE: "Seleziona una scatola per ciascuna riga dell'ordine",
        QUANTITA_CONFERMATA: "Quantità confermata",
        QUANTITA_RICHIESTA: "Quantità richiesta",
        SELEZIONA_UNA_SCATOLA: "Seleziona una scatola...",
        SELEZIONA_SCATOLE: "Seleziona scatole",
        CONFERMA_SCATOLA: "Conferma scatola",
        CHIUDI_LAVORAZIONE: "Chiudi lavorazione",
        CHIUDI_ORDINE: "Chiudi ordine",
        STAMPA_TUTTI: "Stampa tutti",
        STAMPA_DI_SERVIZIO: "Stampa di servizio",
        RICHIESTA_CONFERMA_NO_SCATOLE: "Non hai ancora confermato la scelta della scatola per questo ordine",
        RICHIESTA_CONFERMA: "Prima di chiudere la lavorazione di questo ordine, controlla di avere stampato i documenti necessari.",
        PRINT_ERRORS: {
            TITLE: "Si sono verificati i seguenti errori in fase di stampa:",
            GENERIC: "Generico",
            DOCUMENT_NOT_PDF: "Il documento richiesto non è un pdf",
            ORDER_DOCUMENTS_MISSING: "Manca il documento nell'ordine",
            URL_NOT_FOUND: "URL del documento non trovata",
            UNAUTHORIZED: "Accesso al documento non autorizzato",
            CETTIRE_NO_ORDER: "Ordine Cettire non confermato e quindi non disponibile per la stampa",
            NO_ORDER_ITEM_ID: "Order item id non presente",
            CONFIRM_ORDER_ITEM: "Si è verificato un errore nella conferma della riga dell'ordine Cettire.",
            UNKNOWN_ERROR: "Errore sconosciuto",
            SERVICE: "Stampa di servizio",
        },
        ZEBRA_ERRORS: {
            OK: "La stampante funziona correttamente.",
            PAPER_OUT: "Carta assente o terminata",
            RIBBON_OUT: "Ribbon assente o terminato",
            MEDIA_DOOR_OPEN: "Sportello aperto",
            CUTTER_FAULT: "Errore nel cutter",
            PRINTHEAD_OVERHEATING: "Testina di stampa surriscaldata",
            MOTOR_OVERHEATING: "Motore surriscaldato",
            PRINTHEAD_FAULT: "Errore nella testina di stampa",
            INCORRECT_PRINTHEAD: "Testina di stampa non corretta",
            PRINTER_PAUSED: "Stampante in pausa",
            ERRORE_SCONOSCIUTO: "Errore sconosciuto. Controllare che la stampante Zebra sia accesa e collegata correttamente.",
        },
        CETTIRE: {
            ALMENO_UNA_RIGA_DA_CONFERMARE: "Per stampare è necessario confermare almeno un articolo per il magazzino selezionato.",
            NON_TUTTI_CONFERMATI_PER_WH: "Non tutti gli articoli sono completamente confermati per il magazzino selezionato. Vuoi procedere ugualmente con la stampa?",
        }
    },
    PAGE_RESERVATIONS: {
        AGGIUNGI_RISERVA: "Aggiungi riserva",
        ELIMINA_RISERVE: "Elimina riserve",
        SCARICA_EXCEL: "Scarica Excel",
        ARTICOLI: "__COUNT__ articoli",
        SELEZIONARE_SHOP_SKU_PARENT: "Selezionare shop e sku parent",
        TABLE: {
            ARTICOLO: "Articolo",
            PREZZO: "Prezzo",
            MAGAZZINO: "Magazzino",
            SHOPS: "Shop",
            QTY: "Qty",
            DATA_CREAZIONE: "Data creazione",
            DATA_MODIFICA: "Data modifica",
            QTY_RENDER: "__QTY__ di __WH_QTY__",
            SHOP_TOOLTIP: "Clicca per andare alla pagina dello shop __SHOP_NAME__, alt+click per filtrare le riserve per questo shop",
            WH_TOOLTIP: "Premi alt+click per filtrare le riserve per questo magazzino",
        },
        SHOP_SKU_PARENT_VALIDI: "Gli shop e lo sku parent selezionati sono validi.",
        VALIDAZIONE_IN_CORSO: "Validazione in corso...",
        NEW_RESERVATION_CHECK_ERRORS: {
            SHOP_NON_IN_BUCKETS: "I seguenti shop non appartengono a nessuno dei bucket disponibili all'utente:",
            ARTICOLO_NON_TROVATO: "Articolo non trovato.",
            SKU_CON_BUCKET_MANCANTI: "I seguenti sku non sono disponibili ad alcuni dei bucket utilizzati dagli shop selezionati:",
            SKU_CON_SHOP_ESCLUSI: "I seguenti sku hanno, tra gli shop esclusi dai listing, almeno uno degli shop selezionati:",
            WHS_NON_DISPONIBILI: "Non sono disponibili magazzini per gli shop selezionati.",
        },
        NEW_RESERVATION_RESULTS: {
            QUANTITA: "Quantità richiesta",
            ESEGUITI: "Operazioni di riserva eseguite",
            SCARTATI: "Operazioni di riserva scartate",
            IN_ERRORE: "Operazioni di riserva in errore",
        },
        SELEZIONA_MASSIMA_QUANTITA_DISPONIBILE: "Riserva la massima quantità disponibile nel magazzino",
        ATTIVI_SE_SHOP_SKU_PARENT_VALIDATI: "Attivi una volta selezionati e validati gli shop e lo sku parent da utilizzare per la nuova riserva.",
        SELEZIONARE_MAGAZZINO_E_QUANTITA: "Selezionare un magazzino e definire la quantità da riservare.",
        SELEZIONARE_MAGAZZINO_MAX_QTY: "Selezionare un magazzino; verrà riservata la quantità massima disponibile in esso.",
    },
    PAGE_SHOPS: {
        FILTRA_SUCCESSO: "Mostra gli shops confermati",
        FILTRA_ERRORE: "Mostra gli shops in errore generico",
        FILTRA_ERRORE_AUTENTICAZIONE: "Mostra gli shops in errore di autenticazione",
        FILTRA_DISABILITATI: "Mostra gli shops disabilitati",
        FILTRA_IN_ELABORAZIONE: "Mostra gli shops in elaborazione",
        NUOVO_SHOP: "Nuovo Shop",
        TIPO_SHOP: "Tipo di shop",
        NOME_SHOP: "Nome shop",
        TABLE: {
            NOME: "Nome",
            TIPO: "Tipo",
            STATO: "Stato",
            ABILITATO: "Abilitato",
            DISABILITATO: "Disabilitato",
            CODICE: "Codice",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            COPIA_MERCHANT_ID: "Copia Merchant ID",
            COPIA_TOKEN: "Copia Token",
            COPIA_BEARER: "Copia Bearer Token",
            ATTIVA: "Attiva shop",
            DISATTIVA: "Disattiva shop",
            CONFERMA_DISATTIVAZIONE_SHOP: "Confermi la disattivazione dello shop __SHOP__?",
            CONFERMA_ATTIVAZIONE_SHOP: "Confermi l'attivazione dello shop __SHOP__?",
            SHOP_ATTIVATO: "Shop __SHOP__ attivato correttamente.",
            SHOP_DISATTIVATO: "Shop __SHOP__ disattivato correttamente.",
            MIINTO_API: "Miinto API",
            COPIA_URL: "Copia URL",
            COPIA_USERNAME: "Copia username",
            COPIA_PASSWORD: "Copia password",
            COPIA_X_API_KEY: "Copia x_api_key",
        }
    },
    PAGE_SHOP: {
        MODIFICA_SHOP: "Modifica shop",
        TIPO_SHOP: "Tipo shop",
        NOME: "Nome",
        CUSTOMER_CODE: "Customer code",
        CONFIGURAZIONI_GENERALI: "Configurazioni generali",
        CONFIGURAZIONI_SPECIFICHE: "Configurazioni specifiche",
        ALTRE_CONFIGURAZIONI: "Altre configurazioni",
        ENDPOINT_PER_SCARICO_CATALOGO: "Endpoint per scarico catalogo",
        CREDENZIALI: "Credenziali",
        LISTINO: "Listino",
        LISTINI: "Listini",
        LISTINO_PIENO: "Listino pieno",
        LISTINO_SCONTATO: "Listino scontato",
        ACCESS_KEY: "Access key",
        SECRET_KEY: "Secret key",
        BRAND_LABEL: "Brand label",
        SELLER_LABEL: "Seller label",
        SHIPPING_POINT: "Shipping point",
        GENERALE: "Generale",
        DEFAULT_CURRENCY: "Default currency",
        LISTING: "Listing",
        FLUSSI: "Flussi",
        CONFIGURAZIONE_FLUSSI_E_LISTING: "Configurazione flussi e listing",
        STRATEGIA_MAGAZZINI: "Strategia magazzini",
        SOVRASCRIVI_CONFIGURAZIONE_ACCOUNT: "Sovrascrivi la configurazione dell'account",
        STRATEGIA: "Strategia",
        CONFERMA_CREAZIONE: "Confermi la creazione dello shop?",
        CONFERMA_MODIFICA: "Confermi le modifiche allo shop?",
        CREAZIONE_AVVENUTA_CON_SUCCESSO: "Creazione dello shop __NAME__ avvenuta con successo",
        MODIFICA_AVVENUTA_CON_SUCCESSO: "Modifica allo shop __NAME__ avvenuta con successo",
        TOKEN: "Token",
        ORDER_PRICE:"Prezzo dell'ordine",
        CREDENZIALI_SHOP_API:"Credenziali shop API",
        CONTROLLO_PREZZI_RICEZIONE_ORDINE: "Controllo prezzi in ricezione ordine",
        INBOUND_ORDER_PRICE_CHECK: "Controlla prezzo durante scarico ordini",
        INBOUND_ORDER_PRICE_CHECK_TOLERANCE_PERC: "Tolleranza nel controllo prezzi",
        API_CREDENTIALS:"Credenziali API",
        SFTP_CREDENTIALS:"Credenziali SFTP",
        OTHER_CONFIGURATIONS:"Altre configurazioni",
        LANGUAGES: "Lingue da pubblicare sullo shop",
        CAMPI_TAG: "Campi tag",
        PUBLISH_ONLY_ITEMS_WITH_QUANTITY: "Pubblica solo articoli con quantità",
        CONTROLLO_ORDINI_PENDING: {
            TITLE: "Controllo ordini pending",
            STATO_DI_ATTIVAZIONE: "Stato di attivazione",
            TOLLERANZA: "Tolleranza (in ore)",
            INVIO_EMAIL_CLIENTE: "Invia email al cliente",
            CONTROLLO_DISABILITATO_SE_INBOUND_ORDER_NON_ATTIVO: "Il controllo ordini pending non è attivabile se lo scarico ordini è disabilitato."
        },
        OPZIONI_PER_VARIAZIONI: {
            TITLE: "Opzioni per le variazioni",
            OPZIONI: "Opzioni",
            DESCRIZIONI_IN_LINGUA: "Descrizioni in lingua per le opzioni",
            NESSUNA_OPZIONE_SELEZIONATA: "Nessuna opzione selezionata",
            NESSUNA_LINGUA_SELEZIONATA: "Nessuna lingua selezionata",
        },
        _24S:{
            USERNAME:"Username",
            PASSWORD:"Password",
            LANGUAGES:"Seleziona una o più lingue"    
        },
        CHANNEL: {
            ERRORE_SALVATAGGIO: "Si è verificato un errore nel salvataggio dei seguenti shop: __SHOPLIST__",
        },
        MIINTO: {
            IDENTIFIER: "Identificativo",
            SECRET: "Secret",
            LANGUAGES: "Lingue da pubblicare sullo shop",
            PRICEBOOKS: "Listini",
            TOKEN: "Token",
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password",
        },
        AMAZON: {
            AMAZON_EUROPE: "Amazon Europa",
            MARKETPLACE: "Marketplace",
            CLIENT_ID: "Client ID",
            MERCHANT_ID: "Merchant ID",
            CLIENT_SECRET: "Client Secret",
            REFRESH_TOKEN: "Refresh token",
            CONFERMA_RIMOZIONE_SHOP: "Confermi la rimozione del marketplace __MARKETPLACE__?",
            SELEZIONARE_ALMENO_UN_MARKETPLACE: "Selezionare almeno un marketplace",
        },
        WINKELSTRAAT: {
            X_API_KEY: "x_api_key",
            TOKEN_CHANNABLE: "Token Channable",
            PROJECT_ID_CHANNABLE: "Project ID Channable",
            SKU_PREFIX: "Prefisso SKU",
            WEBHOOKS_TITLE: "Webhooks",
            WEBHOOKS: {
                SHIPMENT: "Shipment",
                SHIPMENT_TRACK: "Shipment track",
                RMA_ITEMS: "RMA items",
            },
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password",
        },
        ITALIST:{
            PARTIAL_ORDER:"Abilita ordini parziali",
            API_KEY: "api key",
            STORE_KEY:"store key",
        },
        CETTIRE:{
            USERNAME: "Username",
            PASSWORD: "Password",
            USER_ID:"User Id",
        },
        MIRAKL:{
            API_KEY: "api key",
            SHOP_ID: "Shop Id",
        },
        FARFETCH:{
            STORE_ID:"Store Id",
            SAFE_KEY:"Chiave di sicurezza",
            GOT_TO_SHOP:"Dettaglio dello shop",
            MERCHANT_CODE:"Merchant Code",
            MERCHANT_CODE_EXIST: "Valore duplicato in altro shop Farfetch",
            VPI_PORT_ERROR:"la porta deve essere inferiore o uguale a 65535",
            URL: "URL",
            USERNAME: "Username",
            PASSWORD: "Password",
            REST: {
                REST_API: "REST API",
                CONFIGURA_REST_API: "Configura REST API",
                CLIENT_ID: "Client ID",
                CLIENT_SECRET: "Client Secret",
                USERNAME: "Username",
                PASSWORD: "Password",
                PARTNER_ID: "Partner ID",
                USER_AGENT: "User agent",
                MERCHANT_UUID: "Merchant UUID",
            },
            VPI:{
                TITLE:"Vendor Provider Imagery",
                ENABLED:"Abilita Vendor Provider Imagery",
                HOSTNAME:"Nome del Host",
                PORT:"Porta",
                USENAME:"Username",
                PASSWORD:"Password",
            },
            FXFF:{
                TITLE:"Fulfillment",
                ENABLED:"Abilita Fulfillment da Farfetch", 
                LOGISTIC_CENTER:"Centro Logistico",
                LOGISTIC_CENTER_IT:"Italia",
                LOGISTIC_CENTER_HK:"Hong Kong",
                LOGISTIC_CENTER_US:"Stati Uniti",
            },
            SANDBOX:"Sandbox",
            B2B:"B2B",
            ROLE:"Ruolo",
            TABLE_SEC:{
                TITLE:"Lista shops secondari collegati",
                NOME: "Nome Shop",
                CODICE:"Codice",
                COPY_ID:"Copia identificativo Shop",
                SHOP_ID_COPY: "Shop id di __FF__ copiato!",
                GOT_TO_SHOP:"Dettaglio dello shop",
            },
            PRIMARY_SHOP:"Shop Primario",
            BUSINESS_MODEL: "Business Model",
            DEFAULT: "Default",
            BID_ASK: "Bid / Ask",
            QUESTO_SHOP_PRIMARIO: "Questo è uno shop primario.",
            QUESTO_SHOP_SECONDARIO: "Questo è uno shop secondario.",
        },
        SHOPIFY: {
            DISCLAIMER_LINGUA: ()=><div><strong>Attenzione!</strong> L'elenco delle lingue da pubblicare sullo shop, se popolato, deve obbligatoriamente contenere la lingua impostata come default per questo shop su Shopify.</div>,
            CONFIGURAZIONE_METAFIELD_PRODOTTO: "Configurazione Metafield - Prodotto",
            CAMPO_CLOUDSTORE: "Campo CloudStore",
            METAFIELD_SHOPIFY: "Metafield Shopify",
            TIPO: "Tipo",
            NESSUNA_CONFIGURAZIONE: "Nessuna associazione definita.",
            NOTIFICA_CLIENTE_SPEDIZIONE_ORDINE: "Notifica utente alla spedizione dell'ordine",
            SHOP_NAME: "shop_name",
            TOKEN: "token",
        },
    },
    PAGE_WAREHOUSES: {
        NUOVO_MAGAZZINO: "Nuovo Magazzino",
        FILTRA_SYSTEM: "Mostra i magazzini definiti dal sistema",
        FILTRA_USER: "Mostra i magazzini definiti da utente",
        TABLE: {
            NOME: "Nome",
            STATO: "Stato",
            PRIORITA: "Priorità",
            CODICE: "Codice",
            DEFINITO_DA: " Definito da",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            ELIMINA:"Cancella Magazzino",
            CONFERMA_RIMOZIONE_MAGAZZINO: "Confermi l'eliminazione del magazzino __WAREHOUSE__?",
            RIMOZIONE_MAGAZZINO_SUCCESS: "Il magazzino __WAREHOUSE__ è stato eliminato correttamente",
            ADDRESS_LINE: "Indirizzo",
            CITY:"Città",
            PROVINCE:"Provincia",
            PROVINCE_CODE:"Codice",
            POSTAL_CODE:"Codice postale",
            COUNTRY_CODE:"Nazione",
            MODIFY:"Modifica magazzino"
        },
        OPERATION_SUCCESS:"Magazzino salvato correttamente",
        OPERATION_ERROR:"Errore durante il salvataggio del magazzino"
    },
    PAGE_CUSTOMERS:{
        CUSTOMER_DETAILS:"Dettaglio Cliente",
        COPIA_ID: "Copia ID",
        NEW_CUSTOMER:"Nuovo Cliente",
        CUSTOMER:"Cliente",
        RETURN:"Return",
        LOADING: "in caricamento...",
        NOT_FOUND: "Cliente non trovato",
        ON_ERROR: "in errore", 
        CREATION_DATE:"Data creazione",
        ORDINI_ULTIMO_ANNO: "Ordini nell'ultimo anno",
        ORDERS_MADE:"Ordini effettuati nel periodo",
        UPDATED_DATE:"Data ultimo aggionamento",
        ORDER_DATE: "Data ordine",
        SHIPPING_ADDRESSES:"Indirizzi di spedizione",
        ADD_NEW_ADDRESS:"Aggiungi un nuovo indirizzo si spedizione",
        MODIFY_ADDRESS:"Modifica indirizzo di spedizione",
        SHIPPING_ADDRESSES_DEFAULT:" Indirizzo di default",
        NETSUITE_INBOUND_TOOLTIP: "L'inbound customer di NetSuite è attivo, pertanto la maggior parte delle proprietà di questo utente non è modificabile.",
        ERRORE_NOMINATIVO: "Ragione sociale oppure nome e cognome devono essere presenti",
        ERRORE_TELEFONO: "Formato non corretto (es. corretto: +39123456789)",
        USA_COME_SPEDIZIONE: "Usa anche come indirizzo di spedizione",
        COPIA_DA_FATTURAZIONE: "Copia da fatturazione",
        VAI_A_SCHEDA_CLIENTE: "Vai alla scheda cliente",
        RESTA_NELLA_PAGINA: "Resta nella pagina",
        TABS:{
            INFORMAZIONI_GENERALI: {
                TITLE: "Informazioni generali",
                ANAGRAFICA: "Anagrafica",
                CONTATTI: "Contatti",
                NOME: "Nome",
                COGNOME: "Cognome",
                RAGIONE_SOCIALE: "Ragione sociale",
                CODICE_CLIENTE: "Codice cliente",
                LINGUA: "Lingua",
                EMAIL: "Email",
                TELEFONO: "Telefono",
                CELLULARE: "Cellulare",
                PEC: "PEC",
            },
            FATTURAZIONE: {
                TITLE: "Fatturazione",
                CODICI: "Codici",
                INDIRIZZO: "Indirizzo",
                PARTITA_IVA: "Partita IVA",
                CODICE_FISCALE: "Codice fiscale",
                SDI: "SDI",
                NAZIONE: "Nazione",
                INDIRIZZO_1: "Indirizzo 1",
                INDIRIZZO_2: "Indirizzo 2",
                CITTA: "Città",
                PROVINCIA: "Provincia",
                SIGLA_PROVINCIA: "Sigla provincia",
                CODICE_POSTALE: "Codice postale",
            },
            SPEDIZIONE: {
                TITLE: "Spedizione",
                INDIRIZZI_DI_SPEDIZIONE: "Indirizzi di spedizione",
                NESSUN_INDIRIZZO_PRESENTE: "Al momento non sono definiti indirizzi di spedizione per questo cliente.",
                INFORMAZIONI_GENERALI: "Informazioni generali",
                INDIRIZZO: "Indirizzo",
                CONTATTI: "Contatti",
                AGGIUNGI_INDIRIZZO: "Aggiungi indirizzo",
                MODIFICA_INDIRIZZO: "Modifica indirizzo",
                ALIAS: "Alias",
                NOME: "Nome",
                COGNOME: "Cognome",
                RAGIONE_SOCIALE: "Ragione sociale",
                NAZIONE: "Nazione",
                INDIRIZZO_1: "Indirizzo 1",
                INDIRIZZO_2: "Indirizzo 2",
                CITTA: "Città",
                PROVINCIA: "Provincia",
                SIGLA_PROVINCIA: "Sigla provincia",
                CODICE_POSTALE: "Codice postale",
                EMAIL: "Email",
                TELEFONO: "Telefono",
                CELLULARE: "Cellulare"
            },
            CONDIZIONI_COMMERCIALI: {
                TITLE: "Condizioni commerciali",
                GESTIONE_PREZZI: "Modello di gestione prezzi",
                CONDIZIONE_COMMERCIALE_ATTIVA: "Condizione commerciale attiva",
                STORICO: "Storico delle condizioni commerciali associate",
                NESSUNA_CONDIZIONE_COMMERCIALE_ATTIVA: "Nessuna condizione commerciale attiva per questo customer.",
                NESSUNO_STORICO: "Nessun dato presente.",
                ULTIMA_MODIFICA: "Ultima modifica",
                STATO: "Stato",
                NUMERO_ECCEZIONI: "Numero eccezioni",
                PLACEHOLDER_CONDIZIONE_COMMERCIALE: (date: string, variant?: Variant, label?: string)=> <div className="d-flex align-items-center justify-content-start">
                    <span className="me-2">Data di associazione:</span>
                    <span className="fw-bold me-2">{date}</span>
                    <span className="me-2">Stato attuale dell'associazione:</span>
                    <span className={`fw-bold text-${variant}`}>{label}</span>
                </div>,
                GESTIONE_PREZZI_INFO: (variation:string)=><div>Nel caso in cui questa informazione non sia specificata verrà applicata la gestione prezzi di default per l'account <span className="fw-bold">({variation})</span></div>
            },
            ORDINI: {
                TITLE: "Ordini",
                CONFIGURAZIONE_CONTROLLO_ORDINI_PENDING: "Configurazione controllo ordini PENDING",
                TOLLERANZA: "Tolerance",
            },
            BECOMMERCE: {
                TITLE: "BeCommerce",
                METODI_DI_PAGAMENTO_ABILITATI: "Metodi di pagamento abilitati",
                METODI_DI_SPEDIZIONE_ABILITATI: "Metodi di spedizione abilitati",
                BANK_TRANSFER: "Bonifico bancario",
                PAYPAL: "PayPal",
                CREDIT_CARD: "Carta di credito",
                CUSTOMER: "Corriere del cliente",
                DHL_EXPRESS: "DHL Express",
            },
            ACCOUNT: {
                TITLE: "Account",
                DESCRIPTION: ()=><div>Le operazioni effettuate in questa sezione vengono applicate <span className="fw-bold">immediatamente</span>, senza bisogno di effettuare il salvataggio con l'apposito pulsante in alto a destra.</div>,
                MODIFICA_UTENTE: "Modifica nome utente",
                USERNAME: "Nome utente",
                RESET_PASSWORD: "Reimposta Password",
                STATO_CLIENTE: "Stato cliente",
                ATTIVA_CLIENTE: "Attiva",
                DISATTIVA_CLIENTE: "Disattiva",
                CONFERMA_USERNAME: (username: string)=><><div>Stai per modificare il nome utente di questo cliente in</div><div className="fw-bold my-2">{username}</div><div>Sei sicuro di voler procedere?</div></>,
                USERNAME_GIA_IN_USO: "Questo username è già in uso da un altro cliente.",
                CONFERMA_ATTIVAZIONE: "Sei sicuro di voler attivare questo cliente?",
                CONFERMA_DISATTIVAZIONE: "Sei sicuro di voler disattivare questo cliente?",
                INVIA_RICHIESTA: "Invia richiesta",
                CONFERMA_RESET: "Sei sicuro di voler reimpostare la password per questo cliente?",
            }
        },
        STATUS:{
            ACTIVE:"Attivo",
            INACTIVE:"Inattivo",
        },
        TABLE:{
            USERNAME:" User Name",
            NOMINATIVO: "Nominativo",
            CODE :"Username / Codice Cliente",
            COUNTRY:"Nazione",
            STATUS:"Stato",
            EMAIL:"Email",
            EMAILPEC:"Email pec",
            PHONE:"Telefono",
            CELL:"Cellulare",
            ANAGRAFICA_CLIENTE:"Anagrafica Cliente",
            BILLING_ADDRESS:"Indirizzo Fatturazione",
            SHIPPING_ADDRESS: "Indirizzo Spedizione",
            CF:"Codice Fiscale",
            SDI:"Codice SDI",
            VAT:"Codice Vat",
            ADDRESS_LINE: "Indirizzo",
            CITY:"Città",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            PROVINCE:"Provincia",
            PROVINCE_CODE:"Codice provincia",
            POSTAL_CODE:"Codice postale",
            COUNTRY_CODE:"Nazione",
            SWITCH_DESC:"L'indirizzo di fatturazione coincide con quello di spedizione"
        },
        CONTACT_NAME:"Nome contatto",
        OPERATION_SUCCESS:"Cliente salvato correttamente",
        OPERATION_ERROR:"Errore durante il salvataggio del Cliente",
        ATLEAST_1_STATUS:"Almeno uno stato deve essere selezionato",
        RESET_PWD_SUCCESS:"Reset password avvenuto con successo",
        CHANGE_STATUS_SUCCESS:"Cambio stato del cliente avvenuto con successo",
        CONFIRM_DELETE_ADDRESS:"si vuole procedere con l'eliminazione dell'indirizzo selezionato?",
        ADD_CUSTOMER_ADDRESS:"Aggiungi indirizzo di spedizione",
        CUSTOMER_ADDRESS_LIST:"Lista indirizzi di spezione del cliente"
    },
    PAGE_WHS_MOVEMENTS: {
        FILTRO_AVANZATO: "Filtro avanzato",
        NEW_MOVEMENTS: "Nuovo movimento",
        SCARICA_MOVIMENTI:"Scarica Template",
        CARICA_MOVIMENTI:"Carica movimenti",
        CREATION_PROGRESS:"Creazione movimento in corso",
        CREATION_SUCCESS:"Creazione movimento in corso",
        CREATION_ERROR:"Errore durante la creazione del movimento", 
        TABLE: {
            DATE: "Data",
            ORDER: "Ordine",
            BRAND: "Brand",
            QUANTITY: "Quantità",
            TYPE: "Tipo",
            WAREHOUSE: " Magazzino",
            TAGS: "Tags",
            COPIA_ID: "Copia ID",
            COPIA_ID_ORDER: "Copia ID dell'ordine",
            TYPE_ABSOLUTE:"Assoluto",
            TYPE_DIFFERENTIAL:"Differenziale",
            SKU:"SKU"
        },
        ADD_WHR_MOVEMENTS:{
            TITLE: "Nuovo movimento di magazzino",
            SKU: "SKU",
            SKU_PLACEHOLDER: "Inserisci uno SKU",
            WAREHOUSE :"Magazzino",
            WAREHOISE_PLACEHOLDER: "Seleziona Magazzino",
            TYPE_PLACEHOLDER: "Seleziona Tipologia",
        }, 
        DOWNLOAD_MODAL_TEXT: {
            TITLE:"Istruzioni",
            DESCRIPTION: "Cliccando sul pulsante in basso scaricherai il template del CSV per il caricamento massivo di movimenti di magazzino.",
            COLUMNS: "Il file è composto dalle seguenti colonne:",
            SKU: "rappresenta lo SKU dell'articolo.",
            WH_ID: "identificativo CloudStore del magazzino.",
            WH_ID_INFO:" Ignorato se WH_ID è inserito.",
            WH_CODE:"codice cliente del magazzino.",
            WH_CODE_2: "Ignorato se WH_ID è inserito.",
            QTY: "quantità movimentata. Può essere negativa o positiva. Obbligatorio.",
            QTY_2: "Se il TYPE = 'A', allora il valore dev'essere positivo.",
            TYPE:"tipo di movimento: A = Assoluto, D = Differenziale. Obbligatorio.",
            TAGS:"elenco di valori separati di ','. Usati per assegnare delle etichette aggiuntive al movimento. Opzionale.",
            ALERT:"Uno dei due valori tra WHI_ID e WH_CODE deve essere fornito.",
            OPTIONAL: "Opzionale",
            MANDATORY: "Obbligatorio",
            ATTENTION:"Attenzione"
        },
        UPLOAD_MODAL:{
            TITLE:"Carica nuovi movimenti di magazzino",
            UPLOAD_SUCCESS:"Caricamento avvenuto con successo"
        }
    },
    PAGE_SHIPMENTS :{
        ORDINI: "Ordini",
        NESSUN_ORDINE_PRESENTE: "Nessun ordine presente",
        SHIPMENT :"Spedizione",
        NUOVA_SPEDIZIONE: "Nuova Spedizione",
        FILTRO_AVANZATO: "Filtro avanzato",
        VAI_A_DETTAGLIO: "Vai al dettaglio",
        APRI_ANTEPRIMA: "Apri anteprima",
        COPIA_ID: "Copia ID",
        FILTERS: {
            ORDER_TYPE: "Tipologia di ordine",
            ORFRT: "N° ordine"
        },
        TABLE: {
            ORDER: "Ordine",
            CARRIER: "Vettore",
            STATUS: "Stato",
            TRACKING_NUMBER: "N° Tracking",
            SHIPPING_DATE: "Data spedizione",
            DELIVERY_METHOD: "Metodo di consegna",
            DELIVERY_DATE: "Data consegna",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            ORDER_POPUP_TITLE: "ID Ordini",
            TRACKING_URL: "Tracking URL", 
        },
        PREVIEW: {
            RECIPIENT_INFO: "Informazioni destinatario",
            NAME : "Nome",
            SURNAME : "Cognome",
            SHIPMENT_INFO: "Informazioni spedizione",
            COMPANY: "Società",
            BUYER: "Acquirente",
            ADDRESS: "Indirizzo",
        }
    },
    PAGE_CATALOG: {
        NUOVO_ARTICOLO: "Nuovo articolo",
        FILTRO_AVANZATO: "Filtro avanzato",
        INSERIRE_TRE_CARATTERI_PER_CERCARE: "Inserire almeno tre caratteri per cercare",
        SKU: "SKU",
        BARCODE: "Barcode",
        TITOLO: "Titolo",
        BRAND: "Brand",
        CATEGORIA: "Categoria",
        DISPONIBILITA: "Disponibilità",
        FILTERS: {
            BRAND: "Brand",
            CATEGORIE: "Categorie",
            STAGIONI: "Stagioni",
            CANALE_DI_VENDITA: "Canale di vendita",
            QUANTITY: "Quantità",
            SELEZIONA_LE_CATEGORIE: "Seleziona le categorie...",
            CATEGORIE_SELEZIONATE: "Categorie selezionate"
        }
    },
    PAGE_ITEM: {
        DETTAGLIO_ARTICOLO: (sku_parent:string)=>`Dettaglio articolo ${sku_parent}`,
        ARTICOLO_NON_TROVATO: (sku_parent:string, createFunction: Function)=><>
            <div className="fs-5">Non è stato trovato nessun articolo con codice <span className="fw-bold">{sku_parent}</span>.</div>
            <div className="fs-6 mt-2">Vuoi crearne uno? <span className="cs-link" onClick={()=>createFunction()}>Clicca qui!</span></div>
        </>,
        NUOVO_ARTICOLO: "Aggiungi un articolo di questo prodotto",
        ARTICOLO_CORRENTE: "Articolo corrente",
        RIPARA_ERRORE: "Ripara errore",
        RIPARA_PROPRIETA: (label:string)=><div className="d-flex align-items-center justify-content-start"><span className="me-2">Ripara proprietà</span><span className="fw-bold">{label}</span></div>,
        SCEGLI_VALORE_VARIAZIONE: (label: string)=><span>Scegli uno dei seguenti valori tra quelli già presenti e usalo per le variazioni che sono privi della proprietà <span className="fw-bold">{label}</span>:</span>,
        VARIAZIONI_AGGIORNATE_SUCCESSO: (count: number)=>(count>1)?<span>Sono state aggiornate con successo <span className="fw-bold">{count}</span> variazioni di questo articolo.</span>:<span>È stata aggiornata con successo <span className="fw-bold">una</span> variazione di questo articolo.</span>,
        ERRORI_AGGIORNAMENTO: "Si sono verificati i seguenti errori",
        GESTISCI_TRADUZIONI: "Gestisci traduzioni",
        TRADUCI_DA: (source:string) => <span>Traduci automaticamente da: <span className="fw-bold">{source}</span></span>,
        TRADUZIONE_NON_DISPONIBILE: "Traduzione non disponibile",
        SCELTA_TRADUZIONE_DESC: "Seleziona la lingua di default con cui visualizzare le proprietà in lingua dell'articolo.",
        AGGIUNGI_TRADUZIONE: "Aggiungi traduzione",
        TABS: {
            PANORAMICA: {
                TITLE: "Panoramica",
                DATA_ULTIMA_MODIFICA: "Data ultima modifica",
                CI_SONO_VARIAZIONI: (qty: number, onClick: Function)=>(qty===1)?<div>C'è <span className="fw-bold cs-link" onClick={()=>onClick()}>una variazione</span> di questo articolo.</div>:<div>Ci sono <span className="fw-bold cs-link" onClick={()=>onClick()}>{qty} variazioni</span> di questo articolo.</div>,
            },
            DATI: {
                TITLE: "Dati",
                GENERALI: "Generali",
                PERSONALIZZATI: "Personalizzati",
            },
            IMMAGINI: {
                TITLE: "Immagini",
                COLLOCAMENTO: "Collocamento",
                POSIZIONE: "Posizione",
                SHOP_ESCLUSI: "Shop esclusi",
                NESSUNO_SHOP_ESCLUSO: "Nessuno shop escluso",
                URL_MANCANTE: "L'URL dell'immagine non è disponibile.",
                PRESENZA_IMMAGINE_VARIAZIONI_KO: (needed:number, actual: number)=><span>Questa immagine è presente in <span className="fw-bold">{actual}</span> di <span className="fw-bold">{needed}</span> variazioni.</span>,
                SELEZIONA_IMMAGINE_PER_DETTAGLI: "Seleziona una immagine a sinistra per visualizzarne i dettagli.",
                PERCORSO_IMMAGINE: "Percorso immagine",
                TUTTE_LE_VARIAZIONI: "Agisci su tutte le variazioni dell'articolo",
                VARIAZIONE: (sku: string)=>`Agisci sulla variazione ${sku}`,
                AGGIUNGI_IMMAGINE: "Aggiungi una immagine",
                ERRORE_VALORE_POS: "La posizione deve essere 1 o superiore.",
                ERRORE_POS_DUPLICATA: "In una delle variazioni selezionate esiste già una immagine con questa posizione.",
                CONFERMA_ELIMINAZIONE: "Confermi l'eliminazione dell'immagine selezionata?",
            },
            AB: {
                TITLE: "Availability Buckets",
                AGGIUNGI_AB: "Aggiungi un Availability bucket",
                QUANTITA: "Quantità",
                PREZZI: "Prezzi",
                GENERALE: "Generale",
                SHOP_SPENTO: "Questo shop è spento",
                PREZZO: "Prezzo",
            },
            VARIAZIONI: {
                TITLE: "Variazioni",
                SELEZIONA_VARIAZIONE: "Seleziona una variazione",
            },
            ARTICOLI_COLLEGATI: {
                TITLE: "Articoli collegati",
                AGGIUNGI_COLLEGAMENTO: "Aggiungi il collegamento a un articolo",
                AGGIUNGI_COLLEGAMENTO_INFO: "In questa sezione puoi collegare la variazione selezionata a uno o più articoli diversi.",
                TIPO_COLLEGAMENTO: "Tipo di collegamento",
                GIFT: "Omaggio",
                RELATED: "Relazionato",
                QUANTITA: "Quantità",
                SHOP_ESCLUSI: "Shop esclusi",
                ARTICOLO_NON_TROVATO: "Variazione non trovata",
                ERRORE_ARTICOLO_UGUALE: "Non è possibile collegare una variazione di un articolo a sé stessa",
                ERRORE_ARTICOLO_GIA_COLLEGATO: "La variazione dell'articolo scelta è già collegata",
                VARIAZIONE: "Variazione",
                SCOLLEGA: "Scollega articolo",
                VAI_A_ARTICOLO: "Vai all'articolo",
                CONFERMA_SCOLLEGAMENTO: "Confermi l'eliminazione del collegamento con la variazione selezionata?",
            },
            MAGAZZINI: {
                TITLE: "Magazzini",
                MAGAZZINO: "Magazzino",
                DISPONIBILITA: "Disponibilità",
                QUANTITA_RISERVATE: "Quantità riservate",
            },
            MOVIMENTI_MAGAZZINO: {
                TITLE: "Movimenti di magazzino",
                TABLE: {
                    DATE: "Data",
                    QUANTITA: "Quantità",
                    TIPO: "Tipo",
                    MAGAZZINO: "Magazzino",
                    TAGS: "Tags",
                },
                ASSOLUTO: "Assoluto",
                DIFFERENZIALE: "Differenziale",
            },
            AGGIORNAMENTI_DISPONIBILITA: {
                TITLE: "Aggiornamenti disponibilità",
            },
            LISTINI: {
                TITLE: "Listini",
                NOME_LISTINO: "Nome listino",
                VARIAZIONI: "Variazioni",
                TUTTE_LE_VARIAZIONI: "Tutte le variazioni",
                PREZZO: "Prezzo",
            },
            CONDIZIONI_COMMERCIALI: {
                TITLE: "Condizioni commerciali",
                NOME_CONDIZIONE_COMMERCIALE: "Nome condizione commerciale",
                VARIAZIONI: "Variazioni",
                TUTTE_LE_VARIAZIONI: "Tutte le variazioni",
                PREZZO: "Prezzo",
            },
            CODICI_SHOP: {
                TITLE: "Codici shop",
                TIPO_SHOP: "Tipo shop",
                NOME_SHOP: "Nome shop",
                CODICE_SHOP: "Codice shop",
                CODICE_ARTICOLO_SHOP: "Codice articolo nello shop"
            }
        },
        ANALYSIS: {
            NESSUN_PROBLEMA_RILEVATO: "Nessun problema rilevato.",
            ERRORS: {
                GENERIC: (entry: PropertyAnalysis)=><div><span className="fw-bold">{entry.field}:</span> presenta un errore.</div>,
                TOTALLY_MISSING: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}:</span> mancante in tutte le variazioni dell'articolo.</div>,
                PARTIALLY_MISSING: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}:</span> mancante in alcune delle variazioni dell'articolo.</div>,
                PROPERTY_DIFFERENT: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}:</span> in alcune variazioni dell'articolo ha valori diversi da quello principale: <span className="fw-bold">{((entry.additional_info?.less_frequent_values ?? []) as string[]).join(', ')}</span>.</div>,
                PROPERTY_NOT_DISTINCT: (entry:PropertyAnalysis)=><div><span className="fw-bold">{entry.field}:</span> alcune variazioni dell'articolo hanno i seguenti valori in comune: <span className="fw-bold">{((entry.additional_info?.more_frequent_values ?? []) as string[]).join(', ')}</span>.</div>,
                ABS_DIFFERENT: (entry:PropertyAnalysis)=><div><span className="fw-bold">Availability Buckets:</span> differenti tra le variazioni dell'articolo.</div>,
                ABS_PRICE_DIFFERENT: (entry:PropertyAnalysis)=><div><span className="fw-bold">Availability Buckets:</span> prezzo differente per la variazione <span className="fw-bold">{entry.additional_info?.sku! as string} ({`${entry.additional_info?.price? `${entry.additional_info?.price} €`:`non definito`}`})</span></div>,
            }
        },
        FIELD_NAMES: {
            SKU: "SKU",
            SKU_PARENT: "SKU Parent",
            MNF_CODE: "MNF Code",
            SEASON: "Stagione",
            BRAND: "Marca",
            TITLE: "Titolo",
            DESC: "Descrizione",
        }
    },
    PAGE_BINDINGS: {
        SELEZIONARE_UNO_SHOP: "Selezionare uno shop per iniziare",
        ATTRIBUTI_COMPLETAMENTE_ASSOCIATI: "Attributi completamente associati",
        SELEZIONARE_UN_ATTRIBUTO: "Selezionare un attributo dall'elenco",
        NUOVO_ATTRIBUTO: "Aggiungi un nuovo attributo",
        NUOVA_REGOLA: "Aggiungi una nuova regola",
        REGOLE_PER_ATTRIBUTO: "Regole per l'attributo",
        FILTRA_ASSOCIAZIONI_ATTIVE: "Visualizza le associazioni già attive",
        INDOVINA_ASSOCIAZIONI: "Autocompila le associazioni cercando di dedurre i valori dello shop da selezionare in base ai valori CloudStore",
        RIPRISTINA_ASSOCIAZIONI: "Annulla tutte le modifiche correnti e riporta le associazioni allo stato iniziale",
        AGGIUNGI_ASSOCIAZIONE: "Aggiungi una associazione inserendo un valore CloudStore non presente",
        SE_SI_VERIFICANO_CONDIZIONI: "Se si verificano le seguenti condizioni",
        LE_ASSOCIAZIONI_SONO_LE_SEGUENTI: "Le associazioni sono le seguenti",
        REGOLA_DEFAULT_NESSUNA_CONDIZIONE: "Questa è la regola di default. Non si possono aggiungere condizioni, le associazioni saranno sempre attive se le altre regole non hanno validità.",
        NESSUNA_CONDIZIONE: "Nessuna condizione definita. Le associazioni sono sempre valide.",
        BINDINGS_TABLE: {
            VALORE_CS: "Valore CloudStore",
            VALORE_SHOP: "Valore Shop"
        },
        CONFERMA_GUESS_BINDINGS: "Il sistema proverà a compilare automaticamente i valori degli shop non ancora associati a partire dai corrispondenti valori CloudStore. Vuoi proseguire?",
        CONFERMA_RIPRISTINO_BINDINGS: "Se prosegui, tutte le modifiche non salvate alle associazioni andranno perdute."
    },
    PAGE_ORDERS: {
        SCARICA_ORDINI: "Scarica ordini",
        ORDINE: "Ordine",
        STATO_ORDINE: "Stato ordine",
        DATA: "Data",
        CONTROVALORE: "Controvalore",
        NUMERO_ARTICOLI: "Numero articoli",
        APRI_ANTEPRIMA: "Apri anteprima",
        VAI_A_DETTAGLIO: "Vai al dettaglio",
        COPIA_ID: "Copia ID",
        COPIA_CODICE_ORDINE: "Copia codice ordine",
        INFORMAZIONI_ACQUIRENTE: "Informazioni acquirente",
        SPEDIZIONE: "Spedizione",
        FATTURAZIONE: "Fatturazione",
        ORDINI_TROVATI: "__COUNT__ ordini trovati",
        STATUS_CHANGE: "Cambia stato dell'ordine",
        NESSUN_DOCUMENTO_PRESENTE: "Nessun documento presente",
        NESSUN_CUSTOMER_PRESENTE: "Nessun cliente",
        NESSUNA_SPEDIZIONE_PRESENTE: "Nessuna spedizione presente",
    },
    PAGE_AVAILABILITY_ADJUSTMENTS:{
        FILTERS: {
            MANDATORY_FILTERS: "È necessario selezionare uno shop e un articolo per effettuare una ricerca.",
        },
        TABLE:{
            DATE : "Data",
            SHOP : "Shop",
            QUANTITY: "Quantità",
            REASON: "Causa",
            STATE:"Stato",
            MESSAGE: "Messaggio",
            COMPLETED :"Completo",
            INCOMPLETED: "Incompleto",
            LISTING: "Listing",
            MOVEMENT: "Movimento" 
        }
    },
    PAGE_ORDER: {
        ORDINE: "Ordine",
        DETTAGLIO_ORDINE: "Dettaglio ordine",
        ULTIMO_AGGIORNAMENTO: "Ultimo aggiornamento",
        NESSUN_PROBLEMA_RISCONTRATO: "Non sono stati riscontrati problemi sull'ordine.",
        PROBLEMI_RISCONTRATI: "Sono stati riscontrati problemi sull'ordine.",
        IN_CARICAMENTO: "in caricamento...",
        NON_TROVATO: "non trovato",
        IN_ERRORE: "in errore",
        ORDINE_NON_TROVATO: "L'ordine richiesto non è stato trovato.",
        ORDINE_IN_ERRORE: "Si è verificato un errore durante il caricamento dell'ordine, si prega di riprovare.",
        PANORAMICA: "Panoramica",
        ARTICOLI: "Articoli",
        INFORMAZIONI: "Informazioni",
        MOVIMENTAZIONI: "Movimentazioni",
        DOCUMENTI: "Documenti",
        NETSUITE: "NetSuite",
        METADATA: "Metadata",
        DATA: "Data",
        SKU: "SKU",
        ARTICOLO: "Articolo",
        BRAND: "Brand",
        QUANTITA: "Quantità",
        MODIFICA_QUANTITA: "Modifica quantità",
        PREZZO_UNITARIO: "Prezzo unitario",
        PREZZO_TOTALE: "Prezzo totale",
        SUBTOTALE: "Subtotale",
        TOTALE_ORDINE: "Totale ordine",
        SPESE_DI_SPEDIZIONE: "Spese di spedizione",
        SCONTO: "Sconto",
        MARKUP: "Markup",
        TIPO: "Tipo",
        MAGAZZINO: "Magazzino",
        TAGS: "Tags",
        ASSOLUTO: "Assoluto",
        DIFFERENZIALE: "Differenziale",
        MOVIMENTI_MAGAZZINO: "Movimenti di magazzino",
        COPIA_ID_MAGAZZINO: "Copia l'ID del magazzino",
        ID_MAGAZZINO_COPIATO: "ID del magazzino __WH__ copiato!",
        UTENTE: "Utente",
        MODULO: "Modulo",
        SHOP: "Shop",
        AGGIORNAMENTI_DISPONIBILITA: "Aggiornamenti disponibilità",
        STATO: "Stato",
        MESSAGGIO: "Messaggio",
        NUOVO_DOCUMENTO: "Nuovo documento",
        DOCUMENTI_DI_TIPO: "Documenti di tipo",
        ELIMINA_DOCUMENTO: "Elimina documento",
        STAMPA_DOCUMENTI_SELEZIONATI: "Stampa documenti selezionati",
        SELEZIONA_PER_LA_STAMPA: "Seleziona col tasto destro, rimuovi col tasto sinistro",
        TIPO_DOCUMENTO: "Tipo documento",
        NESSUN_DOCUMENTO_DISPONIBILE: "Nessun documento disponibile per quest'ordine.",
        ELIMINA_DOCUMENTO_CONFERMA: "Sei sicuro di voler eliminare il documento",
        DOCUMENTO_ELIMINATO: "Documento eliminato con successo",
        CARICAMENTO_IN_CORSO: "Caricamento in corso...",
        NOME: "Nome",
        EMAIL: "Email",
        ID: "ID",
        CHANGE_ORDER_SATUS:"Inserisci il nuovo stato dell'ordine",
        CANCEL:"Annulla",
        CHANGE_STATUS:"Cambia stato",
        CHANGE_STATUS_CONFIRM_MESSAGE: (status:string)=><div>Si vuol procedere con il cambio di stato in <span className="fw-bold">{status}</span>?</div>,
        CHANGE_STATUS_SUCCESS:()=><><div>Richiesta inoltrata correttamente.</div><div>È possibile che occorra qualche secondo perché l'operazione di cambio di stato sia finalizzata, pertanto ti chiediamo di controllare tra poco il risultato.</div></>,
        SHIPPING_INFO: {
            NOME_AZIENDA: "Nome azienda",
            NOME: "Nome",
            COGNOME: "Cognome",
            CONTATTO_1: "Contatto 1",
            CONTATTO_2: "Contatto 2",
            VIA: "Via / piazza / altro",
            NUMERO: "N°",
            CITTA: "Città",
            CAP: "CAP",
            PROVINCIA: "Provincia",
            CODICE_PROVINCIA: "Codice provincia",
            NAZIONE: "Nazione",
        },
        BILLING_INFO: {
            NOME_AZIENDA: "Nome azienda",
            NOME: "Nome",
            COGNOME: "Cognome",
            PARTITA_IVA: "Partita IVA",
            CODICE_FISCALE: "Codice fiscale",
            PIVA: "P.IVA",
            CF: "C.F.",
            CONTATTO_1: "Contatto 1",
            CONTATTO_2: "Contatto 2",
            VIA: "Via / piazza / altro",
            NUMERO: "N°",
            CITTA: "Città",
            CAP: "CAP",
            PROVINCIA: "Provincia",
            CODICE_PROVINCIA: "Codice provincia",
            NAZIONE: "Nazione",
            ID_FATTURA: "ID fattura",
            NUMERO_FATTURA: "Numero fattura",
            DATA_FATTURA: "Data fattura",
            TOTALE_FATTURA: "Totale fattura",
            URL: "Url",
            METODO_PAGAMENTO: "Metodo di pagamento"
        },
        TAB_NETSUITE: {
            ID_ELEMENTO: "ID Elemento",
            CODICE_ESTERNO: "Codice esterno",
            DATA_CREAZIONE: "Data creazione",
            DATA_ULTIMO_AGGIORNAMENTO: "Data ultimo aggiornamento",
            STATO: "Stato",
            NUMERO_ESECUZIONI: "Numero di esecuzioni",
            NOME_SHOP: "Nome shop",
            ESEGUI_ELEMENTO: "Esegui elemento",
            INVIA_ORDINE: "Invia ordine a NetSuite",
            INVIA_ORDINE_CONFERMA: "Confermi l'invio dell'ordine a NetSuite?",
            INVIA_ORDINE_ESEGUITO: "L'ordine è stato messo in coda per l'invio a NetSuite.",
            INVIA_ORDINE_NON_ESEGUITO_GIA_ESECUZIONE: "L'ordine è già in coda per l'invio a NetSuite.",
        },
        TAB_METADATA: {
            NESSUN_METADATO_DISPONIBILE: "Nessun metadato disponibile.",
            FINANCE_MODULE_DATA: "Dati del modulo Finance",
            TAXES_SOURCE: "Origine delle tasse",
            CALCOLATE_DALLO_SHOP: "Calcolate dallo shop",
            CALCOLATE_DA_CLOUDSTORE: "Calcolate da CloudStore",
            TASSAZIONE_SPESE_DI_SPEDIZIONE: "Tassazione spese di spedizione",
            TASSAZIONE_ARTICOLI: "Tassazione articoli",
            OPZIONI_DI_PROCESSAMENTO: "Opzioni di processamento",
            ORDINE_PARZIALE: "Ordine parziale",
            MAGAZZINI_SELEZIONATI_PER_SCARICO: "Magazzini selezionati per lo scarico",
            CONTROLLO_PREZZI_INBOUND_ORDER: "Controllo prezzi Order Inbound",
            PRICE_WARNING: "Price Warning",
            UNIT_PRICE_WARNING: "Unit price Tax Excluded Warning",
            TOTAL_PRICE_WARNING: "Total price Tax Excluded Warning",
            RIUSCITO: "Riuscito",
            NON_RIUSCITO: "Non riuscito",
            ACCETTATO_DA_CLOUDSTORE: "Accettato da CloudStore",
            NON_ACCETTATO_DA_CLOUDSTORE: "Non accettato da CloudStore",
            CONTROLLO_DISPONIBILITA_EFFETTIVA_ARTICOLI: "Controllo disponibilità effettiva articoli",
            ESITO: "Esito",
            MESSAGGIO_DI_ERRORE: "Messaggio di errore",
        },
        SALVATAGGIO_INFORMAZIONI_SPEDIZIONE_COMPLETATO: "Salvataggio informazioni di spedizione completato con successo!",
        SALVATAGGIO_INFORMAZIONI_FATTURAZIONE_COMPLETATO: "Salvataggio informazioni di fatturazione completato con successo!",
        INFORMAZIONI_AGGIUNTIVE: "Informazioni aggiuntive",
        NESSUNA_INFORMAZIONE_AGGIUNTIVA: "Nessuna informazione aggiuntiva per questo ordine.",
        DETTAGLI_ULTIMO_INVIO_A_NETSUITE: "Dettagli ultimo invio a NetSuite",
        NESSUN_INVIO_A_NETSUITE: "Nessun invio a NetSuite disponibile per questo ordine.",
        NESSUN_ARTICOLO_PRESENTE: "Non sono presenti articoli nell'ordine.",
        ARTICOLO_NON_PRESENTE: "Articolo non presente nell'inventario.",
        APPLICA_A_SKU_STESSO_PARENT: "Applica a tutte le variazioni dello stesso prodotto presenti nell'ordine",
    },
    PAGE_CATEGORY :{
        CATEGORY:"Categoria",
        TABS:{
            SUBCATEGORY:{
                TITLE:"Sottocategorie",
                SUBCATEGORY_OF:"Elenco delle sottocategorie di",
                SUBCATEGORY_NEW:"Nuova Categoria",
            },
            SHOPS:{
                TITLE:"Associazioni shops",
                NO_CAT_SHOP:"Non è stata riscontrata nessuna mappatura per la categoria",
                DELETE_CAT_TITLE:"Si desidera eleminare la categoria selezionata ?",
                SELECT_CAT:"Seleziona categoria",
                TITLE_CAT_SHOP:"Aggiungi nuova associazione categoria",
                ADD_NEW_CAT_SHOP: "Aggiungi una nuova associazione tra shop e categoria",
                SELECTED_CAT:"Categoria"
            },
            TRANSLATIONS:{
                TRANSLATION: "Traduzione",
                TITLE:"Traduzioni",
                COLUMNS : {
                    LANG:"Lingua",
                    TRANSLATION:"Traduzioni"
                },
                NEW_TRANSLATION:"Aggiungi una nuova traduzione",
                NEW_LANGUAGE:"Selezione nuova lingua",
                NEW_LANGUAGE_TO_ADD:"Nuove lingue da aggiungere",
                NEW_TRANSLATION_ADD_CONFERMATION:"Sei sicuro di agginre le nuove lingua/e",
                REMOVE_TRANSLATION_CONFIRMATION:"Sei sicuro di eliminare la traduzione",
                ADD_NEW_CAT_TRANSLATION: "Premere il bottone aggiungere nuove traduzioni",
            }
        },
        SELECT_CAT_FROM_LIST: "Cerca o seleziona una categoria dal menù di ricerca",
        SEARCH_CAT_TITILE:"Ricerca una categoria",
        SEARCH_PLACEHOLDER:"Min. 3 caratteri",
        ADD_CATEGORY:{
            TITLE: 'Insesisci una nuova categoria',
            NEW_CAT:'Nome nuova categoria',
            SUCCESS:"Nuova categoria aggiunta con successo",
        },
        UPDATE_SHOP_CATEGORY:{
            TITLE: 'Sfoglia le categorie dello shop selezionato',
            SELECTED_CATEGORY : 'Categoria selezionata',
            SELECTED_CATEGORY_NEW : 'Seleziona nuova categoria ...',
        },
        REMOVE_CATEGORY: "Elimina Categoria",
        REMOVE_CATEGORY_TEXT: "Attenzione si sta per eliminare la categoria __CATEGORY__ procedendo con l'operazione si eliminerà sia la categoria che tutte le sotto categorie ",
        REMOVE_CATEGORY_SUCCESS:"Rimozione della categoria __CATEGORY__ avvenuta con successo",
    },
    PAGE_SHOPS_WAREHOUSES_BINDINGS: {
        MAGAZZINI_NON_SELEZIONATI: "I seguenti shop non hanno nessun magazzino selezionato",
        CONFERMA_SALVATAGGIO: ()=><><p>Stiamo per aggiornare la configurazione dei magazzini; questa operazione comporta il ricalcolo delle quantità disponibili per lo Shop per il quale si sta cambiando la configurazione dei magazzini. L'operazione potrebbe richiedere un po' di tempo ed è monitorabile dalle code dei task a lunga esecuzione.</p><p>Contestualmente è possibile creare la procedura di aggiornamento massivo delle quantità sul canale di vendita. È preferibile <strong>NON</strong> eseguire questa operazione se il canale di vendita è appena stato attivato e non possiede ancora un catalogo pubblicato.</p><p><strong>Vuoi comunque procedere alla sincronizzazione del canale di vendita?</strong></p></>,
        SALVA_SENZA_SINCRONIZZARE: "Salva senza sincronizzare",
        SALVA_E_SINCRONIZZA: "Salva e sincronizza",
        SALVATAGGIO_AVVENUTO_CORRETTAMENTE: "Configurazione salvata correttamente",
        EVIDENZIA_RIGA: "Evidenzia questa riga",
        EVIDENZIA_COLONNA: "Evidenzia questa colonna",
        SELEZIONA_RIGA: "Seleziona questa riga (alt+click)",
        SELEZIONA_COLONNA: "Seleziona questa colonna (alt+click)",
        NOTIFY_SAVE_ERRORS: "Si sono verificati i seguenti errori nell'esecuzione dell'operazione",
        SALVATAGGIO_SHOP: "Salvataggio shop",
        RICALCOLO_QUANTITA: "Ricalcolo quantità",
        AGGIORNAMENTO_CANALI: "Aggiornamento canali di vendita",
        NOME_SHOP: "Nome shop",
        STEP: "Step",
        ERRORE: "Errore"
    },
    PAGE_WAREHOUSES_MOVEMENTS: {
        WARNING_MANDATORY_FILTERS: "Selezionare il filtro data e almeno uno tra i filtri sku e magazzino."
    },
    PAGE_RETURNS:{
        DETAILS: "Dettaglio reso",
        RETURN:"Reso",
        LOADING: "in caricamento...",
        NOT_FOUND: "Not found",
        ON_ERROR: "in errore",
        INFO: "Informazioni reso",
        ORIGIN_ORDER: "Ordine di origine",
        REASON:"Motivo reso",
        RESOLUTION:"Risoluzione",
        ITEMS: "Articoli",
        TABLE:{
            QUANTITY:"Quantita",
            STATUS:"Stato",
            DATE:"Data",
            CREATION_DATE:"Data creazione",
            ID:"Identificativo",
            RETURN:"Reso",
            CUSTOMER_CODE: "Codice cliente",
            UPDATE_DATE:"Ultimo aggiornamento",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
        }
    },
    PAGE_LOGS:{
        TRACKING:{
            TRACKING:"Tracking",
            ELEMENT_PROCESSING: "Elaborazione Elemento",
            ELEMENT_CREATION:"Creazione Elemento", 
        },
        SETTINGS:{
            NEW_SETTINGS:"Aggiungi nuova configurazione",
            FREQUENCY:"Frequenza",
            SEARCH_TERMS:"Termini di ricerca",
            NO_SERCH_TERMS:"Nessun termine di ricerca",
            GROUP:"Gruppo",
            LEVELS:"Livelli",
            EMAILS: "Emails",
            COMPONENT:"Componente",
            INSERT_MAILS: "Indirizzi email",
            MODIFY_CONFIG:"Modifica configurazione",
            DELETE_CONFIG:"Cancella configurazione",
            DELETE_CONFIRM_MESSAGE:"Procedere con l'eliminazione della configurazione del registro attività?",
            DELETE_CONFIRM:"Rimozione configurazione avvenuta con successo"
        },
        WAREHOUSES_DETAILS:"Dettaglio magazzini"
    },
    PAGE_QUEUES:{
        SEARCH_TERM_PLACEHOLDER:"Cerca una coda",
        FAVORITES:"Preferiti",
        CORE:"Code core",
        MODULE:"Code moduli",
        EXTERNAL:"Code esterne",
        SEARCH_RESULTS:"Risultati ricerca",
        RERUN_QUEUE:"Riesegui code",
        QUEUE_ERROR:"La Coda richiesta non è stata trovata.",
        QUEUE_NOT_FOUND:"Si è verificato un errore durante il caricamento della coda, si prega di riprovare.",
        QUEUE_EMPTY_BOX:"Non sono stati trovati elementi per la coda selezionata",
        QUEUE_EMPTY_EXECUTIONS_DETAILS:"Non sono state trovate esecuzioni per la coda specifica",
        PANORAMICA: "Panoramica",
        NO_FAVORITES1 : "Nessun preferito selezionato. Per aggiungere delle code ai preferiti cliccare sull’icona "  ,
        NO_FAVORITES2 :"della coda stessa",
        REWORK_CONFERMATIONS:"Confermi la rielaborazione dell'elemento della coda selezionato?",
        REWORK_CONFERMATIONS_ERROR:"Errore durante la rielaborazione dell'elemento della coda",
        TABLE: {
            CREATION_DATE:"Data di creazione",
            UPDATE_DATE:"Data di aggiornamento",
            GO_TO_DETAIL: "Dettagli",
            COPY_ID: "Copia ID",
            EXECUTIONS: "Esecuzioni",
            STATUS: "Stato",
            STATUS_ELABORATED:"Elaborato",
            STATUS_PENDING: "In attesa",
            STATUS_TO_BE_PROCESSED: "Da elaborare",
            STATUS_PROCESSED_SUCCESSFUL:"Elaborazione completata",
            STATUS_PROCESSED_FAILED: "Elaborazione fallita",
            RECORDS:"# record",
            GOTO_DETAILS:"Vai al dettaglio",
            REWORK:"Rielabora nuovamente",
            PROCESSING_TRAKING:"Traking di elaborazione",
            EXTERNAL_CODE:"Codice esterno"
        },
        STATUS: {
            TO_BE_PROCESSED: "In attesa",
            PROCESSING: "In esecuzione",
            PROCESSED_SUCCESSFUL: "Elaborato",
            PROCESSED_FAILED: "Fallito"
        },
        DETAILS: {
            TAB_INFO:"Informazioni",
            TAB_INFO_TITLE_1:"Informazioni Coda",
            TAB_INFO_TITLE_2:"Esecuzione Coda",
            TAB_PAYLOAD:"Payload",
            TAB_DETAIL:"Dettaglio",
            EXTERNAL_CODE:"Codice esterno",
            CREATION_DATE:"Data creazione",
            UPDATE_DATA:"Data ultimo aggiornamento",
            STATUS:"Stato",
            REPORT:"Report",
            TIMELINE_ELEMENT:"Non sono presenti informazioni per questa specifica esecuzione"
        }
    },
    TABLE: {
        PAGINA_NUMERO_DI: "Pagina __NUMBER__ di __TOTAL__",
        RISULTATI: "risultati",
        RISULTATI_FILTRATI_DA: "risultati (filtrati da __TOTAL__ totali)",
        VAI_A: "Vai a...",
        PAGINA: "Pagina",
        NESSUN_RISULTATO: "Nessun risultato.",
        SI_E_VERIFICATO_UN_ERRORE: "Si è verificato un errore, clicca sul pulsante sotto per riprovare."
    },
    DATE_PICKER: {
        DEFAULT_DATE_RANGES: {
            OGGI: "Oggi",
            IERI: "Ieri",
            ULTIMA_SETTIMANA: "Ultima settimana",
            PRIMO_MESE: "Dal primo del mese",
            MESE_PRECEDENTE: "Nel mese precedente"
        },
        SALVA_FILTRO_PERSONALIZZATO: "Salva filtro personalizzato"
    },
    REACT_SELECT: {
        PLACEHOLDER: "Seleziona un valore...",
        NESSUN_RISULTATO: "Nessun risultato trovato."
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "Nuova configurazione"
    },
    FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Selezionare almeno un filtro per visualizzare i risultati.",
        NESSUN_RISULTATO: "Nessun risultato trovato.",
        ERRORE: "Si è verificato un errore, si prega di riprovare."
    },
    ADVANCED_FILTERS: {
        FILTRO_AVANZATO: "Filtro avanzato",
        LABELS: {
            BRAND: "Brand",
            CATEGORIE: "Categorie",
            STAGIONI: "Stagioni",
            CANALE_DI_VENDITA: "Canale di vendita",
            QUANTITY: "Quantità",
            SELEZIONA_LE_CATEGORIE: "Seleziona le categorie...",
            CATEGORIE_SELEZIONATE: "Categorie selezionate",
            DATA: "Data",
            SKU: "Sku",
            STATO: "Stato",
            ORDER_TYPE : "Tipologia di ordine",
            ORDER: "Ordine",
            ORDERS: "Ordini compresi in",
            WAREHOUSES: 'Magazzini',
            SELEZIONA_ELEMENTI: "Seleziona gli elementi...",
            ELEMENTI_SELEZIONATI: "Elementi selezionati",
            COMPONENTS:"Componenti",
            GROUP:"Gruppo",
            CUSTOMER:"Cliente",
            SHOP: "Shop",
            ARTICOLO: "Articolo",
            ARTICOLI: "Articoli compresi in",
        },
        NESSUN_FILTRO_APPLICATO: "Nessun filtro applicato.",
        ERRORE_CARICAMENTO_FILTRI: "Si è verificato un errore nel caricamento dei filtri.",
        SHOP: "Canali di vendita compresi in ",
        BRAND: "Brand compresi in ",
        SEASON: "Stagioni comprese in ",
        E_ALTRI: " e altri",
        E_ALTRE: " e altre",
        DATA: "Data",
        DATA_DA: "da",
        DATA_A: "a",
        IL_GIORNO: "il giorno",
        QUANTITA_NO: "NON devono avere quantità disponibili",
        QUANTITA_SI: "devono avere almeno una quantità disponibile",
        FILTRI_PERSONALIZZATI: "Filtri personalizzati",
        NESSUN_FILTRO_PERSONALIZZATO: "Nessun filtro personalizzato disponibile.",
        SALVA_FILTRO_PERSONALIZZATO: "Salva filtro personalizzato",
        SALVA_FILTRO_PLACEHOLDER: "Filtro personalizzato",
        APPLICA: "Applica",
        ANNULLA: "Annulla",
        RESETTA: "Resetta",
        FILTRO_NON_APPLICATO: "Filtro non ancora applicato",
        CATEGORIES: "Categorie comprese in",
        SKU: "Sku compresi in",
        QUALSIASI_STATO: "Qualsiasi stato",
        IN_STATO: "In stato"
    },
    DOCUMENT_TYPES: {
        COMMERCIAL_INVOICE: "Fattura",
        AIRWAY_BILL: "Lettera di vettura aerea",
        RETURN_INSTRUCTIONS: "Istruzioni di reso",
        RETURN_LABEL: "Etichette per il reso",
        CITES_DECLARATION: "Dichiarazione di Washington",
        ORDER_DESCRIPTION: "Descrizione dell'ordine",
        SHIPPING_DESCRIPTION: "Descrizione della spedizione",
        SHIPPING_LABEL: "Etichette di spedizione",
        OTHER: "Indefinito",
    },
    INPUT_TAGS:{
        ADD_TAGS:"Aggiungi un tag",
        REMOVE:"Clicca pre rimuovere",
        TITLE: "Tags"
    },
    QUEUES: {
        STATUSES: {
            TO_BE_PROCESSED: "In attesa",
            PROCESSING: "In esecuzione",
            PROCESSED_SUCCESSFUL: "Elaborato",
            PROCESSED_FAILED: "Fallito"
        },
        CARD:{
            ACCOUNT:"Account",
            SHOP:"Shop",
            AMAZON: "Amazon",
            BC:"Business Conditions",
            WHS:"Warehouses",
            ERP:"Modulo Erp",
            NETSUITE:"NetSuite",
            SAVAIT:"Savait",
            FXFF:"Fxff",
            TON:"Tony",
            RETAILPRO:"RetailPro",
            EFASHION:"e-Fashion",
            NEW_EXTERNAL:"Nuove code esterne",
            THE_CORNER:"The Corner",
            NEW_QUEUE:"Nuove code"
        },
        DESCRIPTIONS: {
            UNKNOWN_QUEUE: "Coda personalizzata",
            INTERNAL_QUEUE_IN_CLOUDSTORE: "Coda interna in CloudStore",
            EXTERNAL_QUEUE_TO_CLOUDSTORE: "Coda esterna a CloudStore",
            ITEMS_TO_BE_PROCESSED: "Elementi da elaborare",
            ITEMS_IN_ERROR: "Elementi in errore",
            NO_ERROR: "Nessun errore!",
            DEFAULT_DESCRIPTION: "Non è disponibile una descrizione per la coda",
            OUTBOUND: {
                MOD_M2_PRICE_QUANTITY: {
                    LABEL: "Outbound prezzi e quantità",
                    DESCRIPTION: "Esportazione informazioni di prezzi e quantità verso l'ESB."
                },
                MOD_M2_ITEMS: {
                    LABEL: "Outbound articoli",
                    DESCRIPTION: "Esportazione informazioni di anagrafiche articoli verso l'ESB."
                },
                TON_ITEM_NS: {
                    LABEL: "Outbound item",
                    DESCRIPTION: "Esportazione anagrafica articoli da CloudStore a NetSuite"
                },
                SVT_ORDER: {
                    LABEL: "Outbound ordini a Account Partner",
                    DESCRIPTION: "Outbound ordini partner da Account Marketplace ad Account Partner"
                },
                SVT_AC2SS: {
                    LABEL: "Outbound ordini a Savait Servant",
                    DESCRIPTION: "Outbound ordini partner da Account Marketplace a Savait Servant"
                },
                ERP_ORDERS: {
                    LABEL: "Outbound ordini di vendita",
                    DESCRIPTION: "Invio all'ERP collegato degli ordini di vendita ricevuti da CloudStore"
                },
                NS_OUTBOUND_SALES_ORDERS: {
                    LABEL: "Outbound ordini di vendita",
                    DESCRIPTION: "Invio a NetSuite degli ordini di vendita ricevuti da CloudStore"
                },
                NS_OUTBOUND_TRANSFER_ORDERS: {
                    LABEL: "Outbound ordini di trasferimento",
                    DESCRIPTION: "Invio a NetSuite degli ordini di trasferimento ricevuti da CloudStore"
                },
                OTHER_OUTBOUND_PACKAGES: {
                    LABEL: "Invio informazioni di packaging",
                    DESCRIPTION: "Invio delle informazioni di packaging verso gli Shop che hanno questo flusso attivo",
                    EXTERNAL_KEY: "Ordine da spedire",
                    PARTITON_ID:  "Shop",
                },
                OTHER_OUTBOUND_SHIPMENTS: {
                    LABEL: "Invio spedizioni",
                    DESCRIPTION: "Invio delle spedizioni verso gli Shop che hanno questo flusso attivo"
                },
                OTHER_OUTBOUND_RETURN: {
                    LABEL: "Invio resi senza RMA",
                    DESCRIPTION: "Invio delle informazioni relative ai resi senza RMA verso gli Shop che hanno questo flusso attivo"
                },
                OTHER_OUTBOUND_RETURN_REQUEST: {
                    LABEL: "Risposta richiesta resi",
                    DESCRIPTION: "Invio della risposta alla richiesta dei resi verso gli Shop che hanno questo flusso attivo"
                },
                AMZN_FEED_REQUEST_FULFILLMENT: {
                    LABEL: "Feed spedizioni",
                    DESCRIPTION: "Invio informazioni relative alle spedizioni"
                },
                AMZN_FEED_REQUEST_INVENTORY: {
                    LABEL: "Feed inventario",
                    DESCRIPTION: "Invio aggiornamenti inventario articoli"
                },
                AMZN_FEED_RESPONSE: {
                    LABEL: "Feed response",
                    DESCRIPTION: "Letture delle risposte di Amazon ai vari feed"
                },
                TON_OUTBOUND_NS:{
                    LABEL: "Outbound Item verso NetSuite",
                    DESCRIPTION: "Outbound Item verso NetSuite"
                },
                MKPLSVT_SS_ORDER:{
                    LABEL:"Outbound Orders to SCSC",
                    DESCRIPTION:"Outbound Orders to SCSC",
                },
                MKPLSVT_ORDER:{
                    LABEL:"Outbound Orders to CS Connector",
                    DESCRIPTION:"Outbound Orders to CS Connector",
                }
            },
            INBOUND: {
                TON_RP: {
                    LABEL: "Inbound movimenti di magazzino",
                    DESCRIPTION: "Importazione dei movimenti di magazzino generati da Retail Pro"
                },
                EFASHION_ITEMS: {
                    LABEL: "Inbound item da eFashion",
                    DESCRIPTION: "Elaborazione degli articoli da importare in CloudStore da eFashion"
                },
                NS_INBOUND_CASHSALE_INVOICE: {
                    LABEL: "Inbound fatture / scontrini",
                    DESCRIPTION: "Importazione delle informazioni relative agli scontrini e alle fatture"
                },
                NS_INBOUND_CUSTOMER: {
                    LABEL: "Inbound clienti",
                    DESCRIPTION: "Importazione dei clienti che hanno accesso al modulo B2B"
                },
                NS_INBOUND_FULFILLMENTS: {
                    LABEL: "Inbound spedizioni",
                    DESCRIPTION: "Importazione delle informazioni relative alle spedizioni"
                },
                NS_INBOUND_ITEM_AVAILABILITY: {
                    LABEL: "Inbound aggiornamenti quantità",
                    DESCRIPTION: "Importazione delle quantità disponibili rimanenti degli articoli"
                },
                NS_INBOUND_ORDERS: {
                    LABEL: "Inbound aggiornamento ordini",
                    DESCRIPTION: "Importazione degli aggiornamenti relativi agli ordini"
                },
                NS_INBOUND_ITEMS: {
                    LABEL: "Inbound articoli",
                    DESCRIPTION: "Importazione delle anagrafiche degli articoli"
                },
                RP_INBOUND_MOVIMENTI: {
                    LABEL: "Inbound movimenti",
                    DESCRIPTION: "Importazione dei movimenti di magazzino creati su Retail Pro"
                },
                OTHER_INBOUND_ITEMS: {
                    LABEL: "Importazione articoli",
                    DESCRIPTION: "Importazione delle anagrafiche degli articoli"
                },
                OTHER_INBOUND_ITEM_IMAGES: {
                    LABEL: "Importazione immagini articoli",
                    DESCRIPTION: "Importazione delle immagini degli articoli"
                },
                OTHER_BULK_INBOUND_ITEMS: {
                    LABEL: "Importazione flusso articoli",
                    DESCRIPTION: "Importazione massiva di informazioni anagrafiche relative agli articoli"
                },
                OTHER_BULK_INBOUND_ITEM_IMAGES: {
                    LABEL: "Importazione flusso immagini articoli",
                    DESCRIPTION: "Importazione massiva di immagini relative agli articoli"
                },
                OTHER_BULK_INBOUND_WHS_MOVEMENTS: {
                    LABEL: "Importazione flusso movimenti di magazzino",
                    DESCRIPTION: "Importazione massiva di movimenti di magazzino"
                },
                OTHER_INBOUND_WHS_MOVEMENTS: {
                    LABEL: "Importazione movimenti di magazzino",
                    DESCRIPTION: "Importazione di movimenti di magazzino"
                }
            },
            NETSUITE: {
                CONSULT_SEND_ORDER: "Consulta l'invio a NetSuite",
                SEND_ORDER: "Invia ordine a NetSuite",
                SEND_ORDER_NOT_AVAILABLE: "L'ordine è già in coda per essere inviato a NetSuite",
                SEND_ORDER_TITLE: "Invio ordine",
                SEND_ORDER_CONTENT_OK: "L'ordine è stato messo in coda per essere inviato a <strong>NetSuite</strong>.",
                SEND_ORDER_CONTENT_KO: "Non è stato possibile preparare l'ordine da inviare a <strong>NetSuite</strong>, oppure è già in attesa di essere inviato."
            },
            OTHER: {
                REPORT: {
                    LABEL: "Report",
                    DESCRIPTION: "Elaborazione dei report configurati dall'utente"
                },
                LISTING: {
                    LABEL: "Listing",
                    DESCRIPTION: "Creazione dei listing per gli articoli soggetti a pubblicazione automatica"
                },
                LRT: {
                    LABEL: "Attività a lunga esecuzione",
                    DESCRIPTION: "Coda che elabora elementi di lunga esecuzione"
                },
                LISTING_EXCLUSION_RULES: {
                    LABEL: "Regole di esclusione dal listing",
                    DESCRIPTION: "Applicazione delle regole di esclusione dal listing di un sottoinsieme di articoli del catalogo"
                },
                AVAILABILITY_PRICE:{
                    LABEL:"Outbound Availability and Prices",
                    DESCRIPTION:"Invio di quantità e/o prezzi allo Shop.",
                },
                AVAILABILITY_PRICE_RESULT:{
                    LABEL:" Inbound Availability and Prices Result",
                    DESCRIPTION:"Controllo dell’esito dell’invio quantità e/o prezzi allo Shop in caso questo operi in modalità asincrona.",
                }
                
            },
            BC:{
                BUSINESS_CONDITION_1: {
                    LABEL: "Condizioni commerciali shop",
                    DESCRIPTION: "Applicazione delle condizioni commerciali agli shop di un sottoinsieme di articoli del catalogo"
                },
                BUSINESS_CONDITION_2: {
                    LABEL: "Condizioni commerciali item",
                    DESCRIPTION: "Applicazione delle condizioni commerciali agli item di un sottoinsieme di articoli del catalogo"
                }
            },
        }   
    },
    STREAMS: {
        INVENTORY_AND_CATALOG_INBOUND: "Scarico disponibilità / catalogo",
        ORDER_INBOUND: "Scarico ordini",
        ORDER_DOCUMENTS_INBOUND: "Scarico documenti ordine",
        RETURN_INBOUND: "Scarico resi",
        RETURN_REQUEST_INBOUND: "Scarico richieste di reso",
        SHIPMENT_INBOUND: "Scarico spedizioni",
        AVAILABILITY_AND_PRICES_OUTBOUND: "Aggiornamento quantità e prezzi",
        ITEM_OUTBOUND: "Aggiornamento articoli",
        ORDER_CANCELLATION_OUTBOUND: "Aggiornamento cancellazione ordini",
        ORDER_REPLY_OUTBOUND: "Aggiornamento conferma ordini",
        ORDER_PACKAGING_OUTBOUND: "Aggiornamento informazioni di confezionamento",
        RETURN_OUTBOUND: "Aggiornamento resi",
        RETURN_REQUEST_REPLY_OUTBOUND: "Aggiornamento conferma resi",
        SHIPMENT_OUTBOUND: "Aggiornamento spedizioni",
        PAYMENT_OUTBOUND: "Aggiornamento informazioni pagamenti",
        ORDER_INBOUND_PRICE_CHECK_CONFIG: {
            CONTROLLO_ATTIVO: "Controllo attivo",
            TIPO_TOLLERANZA: "Tipo tolleranza",
            ASSOLUTO: "Assoluto",
            PERCENTUALE: "Percentuale",
            VALORE_TOLLERANZA: "Valore tolleranza",
            SCRIVI_NEL_REGISTRO_ATTIVITA: "Scrivi nel Registro Attività",
            BLOCCA_RISPOSTA: "Blocca risposta",
            MAI: "Mai",
            SOLO_SE_MINORE: "Solo se il prezzo è minore del limite minimo",
            SOLO_SE_MAGGIORE: "Solo se il prezzo è maggiore del limite massimo",
            MAGGIORE_MINORE: "Se il prezzo minore del limite minimo o maggiore del limite massimo",
        },
        REPLY_POLICIES: {
            REPLY_ALWAYS: "Automatico senza controllo inventario",
            REPLY_NEVER:"Manuale",
            REPLY_IF_IN_CATALOG:"Automatica solo se in inventario",
            REPLY_IF_IN_CATALOG_AND_AVAILABLE:"Automatica se disponibile"
        },
        ERRORE_POLICY_DIVERSA_NONE_CONTROLLO_PREZZI_NON_ATTIVO: "Se il controllo prezzi non è attivo questo valore dev'essere impostato a 'Mai'.",
        CONTROLLO_PREZZI_DISABILITATO_SE_INBOUND_ORDER_NON_ATTIVO: "Il controllo prezzi non è attivabile se lo scarico ordini è disabilitato.",
        AVAILABILITY_AND_PRICES_OUTBOUND_OPTIONS: {
            AVAILABILITY: "Solo quantità",
            PRICES: "Solo prezzi",
            AVAILABILITY_PRICES: "Quantità e prezzi"
        },
        ITEM_OUTBOUND_ALWAYS_ENABLED: "Per questo shop l'aggiornamento articoli è sempre attivo.",
    },
    OVERRIDE_WAREHOUSE_ALGORITHM: {
        EREDITA_STRATEGIA_DEFAULT: "Eredita la strategia di default",
        NONE: "NONE",
        NO_OP: "Nessuna operazione",
        WHS_PRIORITY: "Priorità magazzini",
        TEMP_COMMIT: "Magazzino di appoggio",
        SHOP_DRIVEN: "SHOP_DRIVEN",
    },
    FORMS: {
        ERRORI_NEL_FORM: "Ci sono errori nei campi del form, si prega di controllare.",
        CAMPO_OBBLIGATORIO: "Campo obbligatorio",
        LUNGHEZZA_COMPRESA: "La lunghezza del campo deve essere compresa tra __MIN__ e __MAX__ caratteri.",
        VALORE_COMPRESO: "Il valore del campo deve essere compreso tra __MIN__ e __MAX__.",
        EMAIL_NON_VALIDA:"mail non valida",
        FORMATO_UUID_NON_VALIDO: "Il valore del campo dev'essere in formato UUID",
        ERRORE_REGEX: "Formato non corretto",
    },
    LANGUAGES:{
        IT: "italiano",
        DE: "tedesco",
        DK: "danese",
        ES: "spagnolo",
        EU: "unione europea",
        FR: "francese",
        EN: "inglese",
        RU: 'russo',
        SE: "svedese",
        CN: "cinese",
        KR: "coreano"
    },
    TREE_SEARCH : {
        OTHER_RESULTS : "Mostra altri risultati ( __REMINIG__ rimanenti )",
    },
    COMPONENT_PRICEBOOKS: {
        CURRENCY: "Valuta",
        STOCK_PRICEBOOK: "Prezzo pieno",
        SALE_PRICEBOOK: "Prezzo scontato",
        AGGIUNGI: "Aggiungi",
        RIMUOVI: "Rimuovi",
        SELECT_PRICEBOOK:"Seleziona listino",
        INCLUDES_VAT:"Tasse Incluse",
    },
    COUNTRIES: {
        SPAGNA: "Spagna",
        REGNO_UNITO: "U.K.",
        FRANCIA: "Francia",
        BELGIO: "Belgio",
        PAESI_BASSI: "Paesi Bassi",
        GERMANIA: "Germania",
        ITALIA: "Italia",
        SVEZIA: "Svezia",
        POLONIA: "Polonia"
    },
    HOME_WIDGETS: {
        ORDINI_DI_OGGI: "Ordini di oggi",
        NESSUN_ORDINE_OGGI: "Nessun nuovo ordine.",
        FATTURATO: {
            TITLE: "Fatturato",
            SUB_TITLE: "Dati degli ordini di oggi",
            TOTALE: "Totale",
            ARTICOLI: "Articoli",
            ORDINI: "Ordini"
        }
    },
    YOOX_SALE_CHANNEL:{
        BE: 'Benelux',
        CEU: 'Europa Centrale',
        DE: 'Germania, Austria',
        DK: 'Scandinavia-Eire',
        EEU: 'Orientale',
        ES: 'Spagna',
        FR: 'Francia',
        GR: 'Grecia',
        IT: 'Italia',
        NL: 'Olanda',
        SEU: 'Europa Meridionale',
    },
    GLF_SALE_CHANNEL:{
        FR: 'Private Sales Channel (Francia)' 
    },
    DECATHLON_SALE_CHANNEL:{
        BE: 'Benelux',
        CEU: 'Europa Centrale',
        DE: 'Germania, Austria',
        DK: 'Scandinavia-Eire',
        EEU: 'Orientale',
        ES: 'Spagna',
        FR: 'Francia',
        GR: 'Grecia',
        IT: 'Italia',
        NL: 'Olanda',
        SEU: 'Europa Meridionale',
    },
    MIRAKL_SALE_CHANNEL_DESC:{
        BE_DESC: 'Belgio, Lussemburgo',
        CEU_DESC: 'Polonia, Romania, Slovacchia, Ungheria, Repubblica Ceca',
        DE_DESC: 'Germania, Austria',
        DK_DESC: 'Danimarca, Finlandia, Irlanda, Svezia',
        EEU_DESC: 'Estonia, Lettonia, Lituania',
        ES_DESC: 'Spagna',
        FR_DESC: 'Francia',
        GR_DESC: 'Grecia',
        IT_DESC: 'Italia',
        NL_DESC: 'Paesi Bassi',
        SEU_DESC: 'Bulgaria, Croazia, Cipro, Malta, Slovenia, Portogallo'
    },
    COUNTRIES_CODE: {
        AC: "Isola di Ascensione",
        AD: "Andorra",
        AE: "Emirati Arabi Uniti",
        AF: "Afghanistan",
        AG: "Antigua e Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antartide",
        AR: "Argentina",
        AS: "Samoa Americane",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Isole Åland",
        AZ: "Azerbaigian",
        BA: "Bosnia ed Erzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgio",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrein",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint-Barthélemy",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        BQ: "Caraibi Olandesi",
        BR: "Brasile",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Isola di Bouvet",
        BW: "Botswana",
        BY: "Bielorussia",
        BZ: "Belize",
        CA: "Canada",
        CC: "Isole Cocos",
        CD: "Congo (Kinshasa)",
        CF: "Repubblica Centrafricana",
        CG: "Congo (Brazzaville)",
        CH: "Svizzera",
        CI: "Costa d'Avorio",
        CK: "Isole Cook",
        CL: "Cile",
        CM: "Camerun",
        CN: "Cina",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Capo Verde",
        CW: "Curaçao",
        CX: "Isola di Natale",
        CY: "Cipro",
        CZ: "Repubblica Ceca",
        DE: "Germania",
        DJ: "Gibuti",
        DK: "Danimarca",
        DM: "Dominica",
        DO: "Repubblica Dominicana",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egitto",
        EH: "Sahara Occidentale",
        ER: "Eritrea",
        ES: "Spagna",
        ET: "Etiopia",
        FI: "Finlandia",
        FJ: "Figi",
        FK: "Isole Falkland",
        FM: "Micronesia",
        FO: "Isole Faroe",
        FR: "Francia",
        GA: "Gabon",
        GB: "Regno Unito",
        GD: "Grenada",
        GE: "Georgia",
        GF: "Guyana Francese",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibilterra",
        GL: "Groenlandia",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadalupa",
        GQ: "Guinea Equatoriale",
        GR: "Grecia",
        GS: "Georgia del Sud e Isole Sandwich Meridionali",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Isole Heard e McDonald",
        HN: "Honduras",
        HR: "Croazia",
        HT: "Haiti",
        HU: "Ungheria",
        ID: "Indonesia",
        IE: "Irlanda",
        IL: "Israele",
        IM: "Isola di Man",
        IN: "India",
        IO: "Territorio Britannico dell'Oceano Indiano",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Islanda",
        IT: "Italia",
        JE: "Jersey",
        JM: "Giamaica",
        JO: "Giordania",
        JP: "Giappone",
        KE: "Kenya",
        KG: "Kirghizistan",
        KH: "Cambogia",
        KI: "Kiribati",
        KM: "Comore",
        KN: "Saint Kitts e Nevis",
        KP: "Corea del Nord",
        KR: "Corea del Sud",
        KW: "Kuwait",
        KY: "Isole Cayman",
        KZ: "Kazakistan",
        LA: "Laos",
        LB: "Libano",
        LC: "Santa Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lituania",
        LU: "Lussemburgo",
        LV: "Lettonia",
        LY: "Libia",
        MA: "Marocco",
        MC: "Monaco",
        MD: "Moldavia",
        ME: "Montenegro",
        MF: "Saint Martin",
        MG: "Madagascar",
        MH: "Isole Marshall",
        MK: "Macedonia del Nord",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Isole Marianne Settentrionali",
        MQ: "Martinica",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldive",
        MW: "Malawi",
        MX: "Messico",
        MY: "Malesia",
        MZ: "Mozambico",
        NA: "Namibia",
        NC: "Nuova Caledonia",
        NE: "Niger",
        NF: "Isola Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Paesi Bassi",
        NO: "Norvegia",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nuova Zelanda",
        OM: "Oman",
        PA: "Panama",
        PE: "Perù",
        PF: "Polinesia Francese",
        PG: "Papua Nuova Guinea",
        PH: "Filippine",
        PK: "Pakistan",
        PL: "Polonia",
        PM: "Saint Pierre e Miquelon",
        PN: "Pitcairn",
        PR: "Porto Rico",
        PS: "Palestina",
        PT: "Portogallo",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Riunione",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Ruanda",
        SA: "Arabia Saudita",
        SB: "Isole Salomone",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Svezia",
        SG: "Singapore",
        SH: "Sant'Elena",
        SI: "Slovenia",
        SJ: "Svalbard e Jan Mayen",
        SK: "Slovacchia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "Sud Sudan",
        ST: "Sao Tome e Principe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Siria",
        SZ: "Swaziland",
        TC: "Isole Turks e Caicos",
        TD: "Ciad",
        TF: "Territori Francesi del Sud",
        TG: "Togo",
        TH: "Thailandia",
        TJ: "Tagikistan",
        TK: "Tokelau",
        TL: "Timor Est",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turchia",
        TT: "Trinidad e Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ucraina",
        UG: "Uganda",
        UM: "Isole Minori Esterne degli Stati Uniti",
        US: "Stati Uniti d'America",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Città del Vaticano",
        VC: "Saint Vincent e Grenadine",
        VE: "Venezuela",
        VG: "Isole Vergini Britanniche",
        VI: "Isole Vergini Americane",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis e Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "Sudafrica",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        UNDEFINED: "Non definito"
    },
    CURRENCY : {
        AED: "Dirham degli Emirati Arabi Uniti",
        AFN: "Afghani",
        ALL: "Lek albanese",
        AMD: "Dram armeno",
        ANG: "Fiorino delle Antille Olandesi",
        AOA: "Kwanza angolano",
        ARS: "Peso argentino",
        AUD: "Dollaro australiano",
        AWG: "Fiorino di Aruba",
        AZN: "Manat azero",
        BAM: "Marco convertibile della Bosnia-Erzegovina",
        BBD: "Dollaro di Barbados",
        BDT: "Taka del Bangladesh",
        BGN: "Lev bulgaro",
        BHD: "Dinaro del Bahrein",
        BIF: "Franco del Burundi",
        BMD: "Dollaro delle Bermuda",
        BND: "Dollaro del Brunei",
        BOB: "Boliviano",
        BRL: "Real brasiliano",
        BSD: "Dollaro delle Bahamas",
        BTN: "Ngultrum del Bhutan",
        BWP: "Pula del Botswana",
        BYN: "Rublo bielorusso",
        BZD: "Dollaro del Belize",
        CAD: "Dollaro canadese",
        CDF: "Franco congolese",
        CHF: "Franco svizzero",
        CLP: "Peso cileno",
        CNY: "Renminbi cinese",
        COP: "Peso colombiano",
        CRC: "Colón costaricano",
        CUC: "Peso cubano convertibile",
        CUP: "Peso cubano",
        CVE: "Escudo capoverdiano",
        CZK: "Corona ceca",
        DJF: "Franco di Gibuti",
        DKK: "Corona danese",
        DOP: "Peso dominicano",
        DZD: "Dinaro algerino",
        EGP: "Sterlina egiziana",
        ERN: "Nakfa eritreo",
        ETB: "Birr etiope",
        EUR: "Euro",
        FJD: "Dollaro delle Figi",
        FKP: "Sterlina delle Falkland",
        GBP: "Sterlina britannica",
        GEL: "Lari georgiano",
        GHS: "Cedi ghanese",
        GIP: "Sterlina di Gibilterra",
        GMD: "Dalasi del Gambia",
        GNF: "Franco della Guinea",
        GTQ: "Quetzal guatemalteco",
        GYD: "Dollaro della Guyana",
        HKD: "Dollaro di Hong Kong",
        HNL: "Lempira honduregno",
        HRK: "Kuna croata",
        HTG: "Gourde haitiano",
        HUF: "Fiorino ungherese",
        IDR: "Rupia indonesiana",
        ILS: "Nuovo siclo israeliano",
        INR: "Rupia indiana",
        IQD: "Dinaro iracheno",
        IRR: "Rial iraniano",
        ISK: "Corona islandese",
        JMD: "Dollaro giamaicano",
        JOD: "Dinaro giordano",
        JPY: "Yen giapponese",
        KES: "Scellino keniano",
        KGS: "Som kirghiso",
        KHR: "Riel cambogiano",
        KMF: "Franco delle Comore",
        KPW: "Won nordcoreano",
        KRW: "Won sudcoreano",
        KWD: "Dinaro kuwaitiano",
        KYD: "Dollaro delle Isole Cayman",
        KZT: "Tenge kazako",
        LAK: "Kip laotiano",
        LBP: "Sterlina libanese",
        LKR: "Rupia dello Sri Lanka",
        LRD: "Dollaro liberiano",
        LSL: "Loti del Lesotho",
        LYD: "Dinaro libico",
        MAD: "Dirham marocchino",
        MDL: "Leu moldavo",
        MGA: "Ariary malgascio",
        MKD: "Denar macedone",
        MMK: "Kyat birmano",
        MNT: "Tugrik mongolo",
        MOP: "Pataca di Macao",
        MRU: "Ouguiya della Mauritania",
        MUR: "Rupia mauriziana",
        MVR: "Rufiyaa delle Maldive",
        MWK: "Kwacha malawiano",
        MXN: "Peso messicano",
        MYR: "Ringgit malese",
        MZN: "Metical mozambicano",
        NAD: "Dollaro namibiano",
        NGN: "Naira nigeriana",
        NIO: "Córdoba nicaraguense",
        NOK: "Corona norvegese",
        NPR: "Rupia nepalese",
        NZD: "Dollaro neozelandese",
        OMR: "Rial omanita",
        PAB: "Balboa panamense",
        PEN: "Sol peruviano",
        PGK: "Kina papuana",
        PHP: "Peso filippino",
        PKR: "Rupia pakistana",
        PLN: "Zloty polacco",
        PYG: "Guaraní paraguaiano",
        QAR: "Rial qatariota",
        RON: "Leu rumeno",
        RSD: "Dinaro serbo",
        RUB: "Rublo russo",
        RWF: "Franco ruandese",
        SAR: "Riyal saudita",
        SBD: "Dollaro delle Isole Salomone",
        SCR: "Rupia delle Seychelles",
        SDG: "Sterlina sudanese",
        SEK: "Corona svedese",
        SGD: "Dollaro di Singapore",
        SHP: "Sterlina di Sant'Elena",
        SLL: "Leone della Sierra Leone",
        SOS: "Scellino somalo",
        SRD: "Dollaro del Suriname",
        SSP: "Sterlina del Sud Sudan",
        STN: "Dobra di São Tomé e Príncipe",
        SYP: "Sterlina siriana",
        SZL: "Lilangeni dello Swaziland",
        THB: "Baht thailandese",
        TJS: "Somoni tagiko",
        TMT: "Manat turkmeno",
        TND: "Dinaro tunisino",
        TOP: "Paʻanga tongano",
        TRY: "Lira turca",
        TTD: "Dollaro di Trinidad e Tobago",
        TWD: "Nuovo dollaro taiwanese",
        TZS: "Scellino tanzaniano",
        UAH: "Hryvnia ucraina",
        UGX: "Scellino ugandese",
        USD: "Dollaro statunitense",
        UYU: "Peso uruguaiano",
        UZS: "Som uzbeko",
        VES: "Bolívar sovrano",
        VND: "Dong vietnamita",
        VUV: "Vatu di Vanuatu",
        WST: "Tala samoano",
        XAF: "Franco CFA BEAC",
        XCD: "Dollaro caraibico orientale",
        XOF: "Franco CFA BCEAO",
        XPF: "Franco CFP",
        YER: "Rial yemenita",
        ZAR: "Rand sudafricano",
        ZMW: "Kwacha zambiano",
        ZWL: "Dollaro zimbabwese"
    }

}

export default it