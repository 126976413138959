import { getShops } from "@api/Shops"
import { FarfetchShop, Shop, ShopListingConfiguration, ShopStreams } from "cs.node.utils/csapi/provisioning"
import { getLocalStorageItem, setLocalStorageItem } from "@utils/LocalStorage"
import MD5 from "md5";
import Localization from "@localization/Index"
import { CSOption } from "@components/Form/CSReactSelect";

/** General Shop Utils */
export enum CloudStoreShopIdEnum {
    _24S                = '6548e520d5340cb90a2c5e27',
    AmazonEu            = '599ed8fa4344a5d7809a13c4',
    Amuze               = '620237cc82be87ce6bcd45d8',
    Cettire             = '66058b86c6dfb244bf294fed',
    Decathlon           = '64357a09a2b9cbb3688bdfe4',
    Ebay                = '53ea6ced777327762af14432',
    Farfetch            = '5b19010ce2e95d19cae7d18c',
    GaleriesLafayette   = "65f9500cb8c7c6fc82c8a24c",
    Italist             = '6490554374c666de2b782869',
    Miinto              = '63298739e0e5f68e0fe4e19e',
    PersonalShop        = '55b62dd274005d12b400e236',
    /* Mirakl            = '61a0ffc51e4cb2cb355c36b4',*/
    Savait              = '63936f79a8dffeed12025013',
    Shopify             = '59e9c29882b58204db50abed',
    ShopifyPos          = '63d0d6d52a49cee3b0a450a9',
    Winkelstraat        = '6579b638dc84dcadd3f83fb1',
    Yoox                = "61a0ffc51e4cb2cb355c36b4",
    Zalando             = '633bde07d19a6256bdc8b6eb',
}

export type CloudStoreShop = {
    id: CloudStoreShopIdEnum
    name: string
    isChannel?: boolean,
    creationEnabled?: boolean
    componentName?: string
    icons: {
        short: string
        extended: string
    },
}
export const cloudStoreShops:CloudStoreShop[] = [
    { id: CloudStoreShopIdEnum._24S,                name: "24S",                    isChannel: false,   creationEnabled: true,  componentName: "_24S",              icons: { short: '24SShort',             extended: '24SExtended'             }},
    { id: CloudStoreShopIdEnum.AmazonEu,            name: "Amazon EU",              isChannel: true,    creationEnabled: true,  componentName: "Amazon",            icons: { short: 'AmazonShort',          extended: 'AmazonExtended'          }},
    { id: CloudStoreShopIdEnum.Amuze,               name: "Amuze Inc",              isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'AmuzeShort',           extended: 'AmuzeExtended'           }},
    { id: CloudStoreShopIdEnum.Cettire,             name: "Cettire",                isChannel: false,   creationEnabled: true,  componentName: "Cettire",           icons: { short: 'CettireShort',         extended: 'CettireExtended'         }},
    { id: CloudStoreShopIdEnum.PersonalShop,        name: "CS Personal Shop",       isChannel: false,   creationEnabled: true,  componentName: "PersonalShop",      icons: { short: 'CSPersonalShopShort',  extended: 'CSPersonalShopExtended'  }},
    { id: CloudStoreShopIdEnum.Decathlon,           name: "Decathlon",              isChannel: false,   creationEnabled: true,  componentName:"Decathlon",          icons: { short: 'DecathlonShort',       extended: 'DecathlonExtended'       }},
    { id: CloudStoreShopIdEnum.Ebay,                name: "Ebay Italia",            isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'EbayItaliaShort',      extended: 'EbayItaliaExtended'      }},
    { id: CloudStoreShopIdEnum.Farfetch,            name: "Farfetch",               isChannel: false,   creationEnabled: true,  componentName: "Farfetch",          icons: { short: 'FarfetchShort',        extended: 'FarfetchExtended'        }},
    { id: CloudStoreShopIdEnum.Italist,             name: "Italist",                isChannel: false,   creationEnabled: true,  componentName:"Italist",            icons: { short: 'ItalistShort',         extended: 'ItalistExtended'         }},
    { id: CloudStoreShopIdEnum.GaleriesLafayette,   name: "Galleries Lafayette",    isChannel: false,   creationEnabled: true,  componentName:"GalleriesLafayette", icons: { short: 'GLFShort',             extended: 'GLFExtended'             }},
    { id: CloudStoreShopIdEnum.Miinto,              name: "Miinto",                 isChannel: false,   creationEnabled: true,  componentName: "Miinto",            icons: { short: 'MiintoShort',          extended: 'MiintoExtended'          }},
    { id: CloudStoreShopIdEnum.Yoox,                name: "Yoox",                   isChannel: false,   creationEnabled: true,  componentName:"Yoox",               icons: { short: 'YooxShort',            extended: 'YooxExtended'            }},
    { id: CloudStoreShopIdEnum.Savait,              name: "Savait",                 isChannel: false,   creationEnabled: true,  componentName: "Savait",            icons: { short: 'SavaitShort',          extended: 'SavaitExtended'          }},
    { id: CloudStoreShopIdEnum.Shopify,             name: "Shopify",                isChannel: false,   creationEnabled: true,  componentName: "Shopify",           icons: { short: 'ShopifyShort',         extended: 'ShopifyExtended'         }},
    { id: CloudStoreShopIdEnum.ShopifyPos,          name: "Shopify POS",            isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'ShopifyPOSShort',      extended: 'ShopifyPOSExtended'      }},
    { id: CloudStoreShopIdEnum.Winkelstraat,        name: "Winkelstraat",           isChannel: false,   creationEnabled: true,  componentName: "Winkelstraat",      icons: { short: 'WinkelstraatShort',    extended: 'WinkelstraatExtended'    }},
    { id: CloudStoreShopIdEnum.Zalando,             name: "Zalando",                isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'ZalandoShort',         extended: 'ZalandoExtended'         }},
]

export const shopStatusThemes = [
    {status: "CONFIRMED", bg:"success", icon:"Success"},
    {status: "ERROR", bg:"danger", icon:"Error"},
    {status: "AUTH_ERROR", bg:"danger", icon:"Error"},
    {status: "PENDING", bg:"warning", icon:"Running"},
]

export const getCloudStoreShop = (id?:CloudStoreShopIdEnum | string) =>{
    if(!id) return undefined
    
    return cloudStoreShops.find(cloudStoreShop=>cloudStoreShop.id===id)
}

/**
 * Get from local storage the list of user shops if exists, else load them from the server
 * If forceRefresh is true, always reload the shops
 * @returns the list of user shops
 */
export const getUserShops = async (options?: {forceRefresh:boolean})=>{
    const { forceRefresh=false } = options ?? {}
    let userShops = getLocalStorageItem('user.shops') as Shop[]
    if(forceRefresh || !userShops) {
        // fetch shops from server
        const shopsResponse = await getShops()
        userShops = shopsResponse.content ?? []
        setLocalStorageItem('user.shops', userShops)
    }

    return userShops
}

export const getShopsFromIds = (shopIds:string[] = []) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return [] }
    return userShops.filter(shop=>shopIds.includes(shop._id?.$oid!))
}

export const getShopFromId = (shopId:string) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return undefined }
    return userShops.find(shop=>shop._id?.$oid === shopId)
}

export const getShopFromName = (shopName:string) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return undefined }
    return userShops.find(shop=>shop.name === shopName)
}

export const getShopFromIdForcedLoad = async (shopId: string) => {
    const userShops = await getUserShops()
    return userShops.find(shop => shop._id?.$oid === shopId)
}

export const getShopsFromCsShopIds =  (cs_shop_id: string) => {
    let userShops = getLocalStorageItem('user.shops') as Shop[]
    return userShops.filter(shop => shop.cs_shop_id?.$oid === cs_shop_id)
}

export const getFarfetchSecondaryShops = (primary_shop_id: string)=>{
    const FFShops =  getShopsFromCsShopIds(CloudStoreShopIdEnum.Farfetch)
    if (!FFShops || FFShops.length<1)  return []

    const filteredShops = FFShops.filter((shop: any): shop is FarfetchShop => {    
        return shop && 'config' in shop && shop?.config?.primary_shop_id?.$oid === primary_shop_id
    })

    return filteredShops
}

export const generateShopToken =  (cs_shop_id: string) => {
    const dataToHash =  new Date().toISOString() + cs_shop_id;
    return MD5(dataToHash).toString();

}

export const getShopStatus = (status: string)=>{
    let bg = "light"
    let icon = null
    const shopStatusTheme = shopStatusThemes.find(theme=>theme.status===status)
    if(shopStatusTheme) {
        bg = shopStatusTheme.bg
        icon = shopStatusTheme.icon
    }

    return {bg, icon}
}

/** STREAMS */


export type CloudStoreShopStream = {
    id: CloudStoreShopStreamId,
    label: string,
}

export enum CloudStoreShopStreamId {
    INVENTORY_AND_CATALOG_INBOUND = 'inventory_and_catalog_inbound',
    ORDER_INBOUND = 'order_inbound',
    ORDER_DOCUMENTS_INBOUND = 'order_documents_inbound',
    RETURN_INBOUND = 'return_inbound',
    RETURN_REQUEST_INBOUND = 'return_request_inbound',
    SHIPMENT_INBOUND = 'shipment_inbound',
    AVAILABILITY_AND_PRICES_OUTBOUND = 'availability_and_prices_outbound',
    ITEM_OUTBOUND = 'item_outbound',
    ORDER_CANCELLATION_OUTBOUND = 'order_cancellation_outbound',
    ORDER_REPLY_OUTBOUND = 'order_reply_outbound',
    ORDER_PACKAGING_OUTBOUND = 'order_packaging_outbound',
    RETURN_OUTBOUND = 'return_outbound',
    RETURN_REQUEST_REPLY_OUTBOUND = 'return_request_reply_outbound',
    SHIPMENT_OUTBOUND = 'shipment_outbound',
    PAYMENT_OUTBOUND = 'payment_outbound',
    // TO BE REMOVED LATER
    AVAILABILITY_OUTBOUND = 'availability_outbound',
    PACKAGING_OUTBOUND = 'packaging_outbound',
}

export const streams:CloudStoreShopStream[] = [
    {id: CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND,      label: Localization.STREAMS.INVENTORY_AND_CATALOG_INBOUND},
    {id: CloudStoreShopStreamId.ORDER_INBOUND,                      label: Localization.STREAMS.ORDER_INBOUND},
    {id: CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND,            label: Localization.STREAMS.ORDER_DOCUMENTS_INBOUND},
    {id: CloudStoreShopStreamId.RETURN_INBOUND,                     label: Localization.STREAMS.RETURN_INBOUND},
    {id: CloudStoreShopStreamId.RETURN_REQUEST_INBOUND,             label: Localization.STREAMS.RETURN_REQUEST_INBOUND},
    {id: CloudStoreShopStreamId.SHIPMENT_INBOUND,                   label: Localization.STREAMS.SHIPMENT_INBOUND},
    {id: CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND,   label: Localization.STREAMS.AVAILABILITY_AND_PRICES_OUTBOUND},
    {id: CloudStoreShopStreamId.ITEM_OUTBOUND,                      label: Localization.STREAMS.ITEM_OUTBOUND},
    {id: CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND,        label: Localization.STREAMS.ORDER_CANCELLATION_OUTBOUND},
    {id: CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND,               label: Localization.STREAMS.ORDER_REPLY_OUTBOUND},
    {id: CloudStoreShopStreamId.ORDER_PACKAGING_OUTBOUND,           label: Localization.STREAMS.ORDER_PACKAGING_OUTBOUND},
    {id: CloudStoreShopStreamId.RETURN_OUTBOUND,                    label: Localization.STREAMS.RETURN_OUTBOUND},
    {id: CloudStoreShopStreamId.RETURN_REQUEST_REPLY_OUTBOUND,      label: Localization.STREAMS.RETURN_REQUEST_REPLY_OUTBOUND},
    {id: CloudStoreShopStreamId.SHIPMENT_OUTBOUND,                  label: Localization.STREAMS.SHIPMENT_OUTBOUND},
    {id: CloudStoreShopStreamId.PAYMENT_OUTBOUND,                   label: Localization.STREAMS.PAYMENT_OUTBOUND},
]

export const getStream = (streamId: CloudStoreShopStreamId) =>{
    return streams.find(stream=>stream.id === streamId)
}

export enum listingConfigurationsEnum {
    FULL            = "FULL",
    PRICE           = "PRICE",
    PRICE_QUANTITY  = "PRICE_QUANTITY",
    QUANTITY        = "QUANTITY",
    REMOVE          = "REMOVE"         
}

export const shopAvailableListingConfigurations: {[key: string]: listingConfigurationsEnum[]} = {
    [CloudStoreShopIdEnum._24S]:                [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.AmazonEu]:            [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Amuze]:               [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Cettire]:             [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Decathlon]:           [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Farfetch]:            [listingConfigurationsEnum.FULL, listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.GaleriesLafayette]:   [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Italist]:             [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Miinto]:              [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.PersonalShop]:        [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Savait]:              [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Shopify]:             [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Winkelstraat]:        [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Yoox]:                [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
    [CloudStoreShopIdEnum.Zalando]:             [                                listingConfigurationsEnum.PRICE, listingConfigurationsEnum.PRICE_QUANTITY, listingConfigurationsEnum.QUANTITY, listingConfigurationsEnum.REMOVE],
}

export const shopAvailableStreams: {[key: string]: CloudStoreShopStreamId[]} = {
    [CloudStoreShopIdEnum._24S]:                [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.RETURN_REQUEST_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.RETURN_REQUEST_REPLY_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.AmazonEu]:            [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Amuze]:               [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Cettire]:             [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Decathlon]:           [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Farfetch]:            [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.SHIPMENT_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.ORDER_PACKAGING_OUTBOUND],
    [CloudStoreShopIdEnum.GaleriesLafayette]:   [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Italist]:             [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Miinto]:              [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.RETURN_REQUEST_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.RETURN_OUTBOUND, CloudStoreShopStreamId.RETURN_REQUEST_REPLY_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.PersonalShop]:        [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Savait]:              [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Shopify]:             [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_CANCELLATION_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Winkelstraat]:        [CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.RETURN_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Yoox]:                [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.RETURN_REQUEST_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.RETURN_OUTBOUND, CloudStoreShopStreamId.RETURN_REQUEST_REPLY_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
    [CloudStoreShopIdEnum.Zalando]:             [CloudStoreShopStreamId.INVENTORY_AND_CATALOG_INBOUND, CloudStoreShopStreamId.ORDER_INBOUND, CloudStoreShopStreamId.ORDER_DOCUMENTS_INBOUND, CloudStoreShopStreamId.RETURN_INBOUND, CloudStoreShopStreamId.AVAILABILITY_AND_PRICES_OUTBOUND, CloudStoreShopStreamId.ITEM_OUTBOUND, CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND, CloudStoreShopStreamId.RETURN_OUTBOUND, CloudStoreShopStreamId.SHIPMENT_OUTBOUND],
}

export const getShopListingConfigurations = (cs_shop_id: CloudStoreShopIdEnum, isPrimaryShop?: boolean):{ [key: string]: ShopListingConfiguration }|undefined =>{
    const shopListingTypes = shopAvailableListingConfigurations[cs_shop_id]
    if(!shopListingTypes) return undefined
    const shopListingConfigurations: {[key: string]: ShopListingConfiguration} = {}
    for(const shopListingType of shopListingTypes) {
        if(!isPrimaryShop && shopListingType === listingConfigurationsEnum.FULL) {
            continue
        }
        shopListingConfigurations[shopListingType] = { enabled: false }
    }

    return shopListingConfigurations
}

export const getShopStreams = (cs_shop_id: CloudStoreShopIdEnum, isPrimaryShop?: boolean):ShopStreams|undefined =>{
    const shopStreamTypes = shopAvailableStreams[cs_shop_id]
    if(!shopStreamTypes) return undefined
    const shopStreams: ShopStreams = {}
    for(const shopStreamType of shopStreamTypes) {
        if(shopStreamType === CloudStoreShopStreamId.ORDER_REPLY_OUTBOUND) {
            shopStreams[shopStreamType] = {enabled: false, reply_policy: "REPLY_NEVER"}
            continue
        }
        if(cs_shop_id === CloudStoreShopIdEnum.Farfetch && shopStreamType === CloudStoreShopStreamId.ITEM_OUTBOUND && !isPrimaryShop) {
            // if Farfetch secondary shop and item outbound, don't add to available streams
            continue
        }

        shopStreams[shopStreamType] = { enabled: false }
    }

    return shopStreams
}

export const getShopOrderReplyOutboundPolicy = (cs_shop_id: CloudStoreShopIdEnum)=>{
    if(cs_shop_id === CloudStoreShopIdEnum.Farfetch) {
        return [
            { value: 'REPLY_ALWAYS', label: Localization.STREAMS.REPLY_POLICIES.REPLY_ALWAYS},
            { value: 'REPLY_IF_IN_CATALOG', label: Localization.STREAMS.REPLY_POLICIES.REPLY_IF_IN_CATALOG},
            { value: 'REPLY_IF_IN_CATALOG_AND_AVAILABLE', label: Localization.STREAMS.REPLY_POLICIES.REPLY_IF_IN_CATALOG_AND_AVAILABLE},
            { value: 'REPLY_NEVER', label: Localization.STREAMS.REPLY_POLICIES.REPLY_NEVER }
        ]
    }
    if(cs_shop_id === CloudStoreShopIdEnum.GaleriesLafayette) {
        return [
            { value: 'REPLY_ALWAYS', label: Localization.STREAMS.REPLY_POLICIES.REPLY_ALWAYS },
            { value: 'REPLY_NEVER', label: Localization.STREAMS.REPLY_POLICIES.REPLY_NEVER }
        ]
    }

    if(cs_shop_id === CloudStoreShopIdEnum.Yoox) {
        return [
            { value: 'REPLY_IF_IN_CATALOG', label: Localization.STREAMS.REPLY_POLICIES.REPLY_IF_IN_CATALOG },
            { value: 'REPLY_NEVER', label: Localization.STREAMS.REPLY_POLICIES.REPLY_NEVER }
        ]
    }

    return undefined
}

export const getShopAvailabilityPriceOutboundOptions = (cs_shop_id: CloudStoreShopIdEnum):CSOption[]=>{
    const arePricesAvailable = [CloudStoreShopIdEnum._24S, CloudStoreShopIdEnum.GaleriesLafayette].includes(cs_shop_id)
    if(arePricesAvailable) {
        return [
            {value: "AVAILABILITY", label: Localization.STREAMS.AVAILABILITY_AND_PRICES_OUTBOUND_OPTIONS.AVAILABILITY},
            {value: "PRICES", label: Localization.STREAMS.AVAILABILITY_AND_PRICES_OUTBOUND_OPTIONS.PRICES},
            {value: "AVAILABILITY_PRICES", label: Localization.STREAMS.AVAILABILITY_AND_PRICES_OUTBOUND_OPTIONS.AVAILABILITY_PRICES},
        ]
    }
    return [
        {value: "AVAILABILITY", label: Localization.STREAMS.AVAILABILITY_AND_PRICES_OUTBOUND_OPTIONS.AVAILABILITY},
    ]
}