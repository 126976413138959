import { CSOption } from "@components/Form/CSReactSelect"
import { CSTableServerPagination } from "@components/Table/CSTable"
import {EntityListResponseWarehouse, EntityListResponseWarehouseMovement, EntityListResponseWarehouseMovementViewSummary, Warehouse as ApiWharehouses } from "cs.node.utils/csapi/provisioning"
import { CurrentFilters } from "@utils/AdvancedFiltersUtils"
import { parseSort } from "@utils/ApiUtils"
import axios, { AxiosResponse } from "axios"
import { checkResponseStatus } from "./Utils"


const getWarehouses = async ():Promise<EntityListResponseWarehouse> =>{
    try {
        const response = await axios.get('api/warehouses')
        return response.data
    } catch(error:any) {
        throw error
    }
}

const createWarehouse = async(warehouse: ApiWharehouses) =>{
    try {
        const params = { warehouse }
        const response = await axios.put(`api/warehouses/create/`,{params})
        return response.data
    } catch(error:any) {
        throw error
    }
}


const updateWarehouse = async(warehouse: ApiWharehouses) =>{
    try {
        const params = { warehouse }
        const response = await axios.put(`api/warehouses/update/`,{params})
        return response.data
    } catch(error:any) {
        throw error
    }
}

const getWarehousesMovementsByOrderIdSummary = async (orderId: string, pagination: CSTableServerPagination):Promise<EntityListResponseWarehouseMovementViewSummary> =>{
    try {
        const { _pageIndex, _pageSize, _sort } = pagination
        const sortParsed = parseSort(_sort, ["date","item.sku","item.title","item.brand","qty","absolute","warehouse.name","tags"])
        const params = {
            _pageIndex, 
            _pageSize,
            _sort: sortParsed,
            orderId
        }
        const response = await axios.get('api/warehouses/movements/listByOrderIdSummary', {
            params
        })
        return response.data
    } catch(error:any) {
        throw error
    }
}


//FIXME: da mettere li altri dati quando ci sarà il servizio corretto
const getWarehousesMovementsByFilters = async ( filters:CurrentFilters, pageIndex: number=0, itemID: string=''):Promise<EntityListResponseWarehouseMovement> =>{
    try {
        const date = filters.find(filter=>filter.id==="date")
        const sku= filters.find(filter=>filter.id==="sku")?.value  as CSOption
        const filterDate = (date?.value?? [null, null]) as [Date|null, Date|null] 
        const dateFrom = filterDate[0]
        const dateTo = filterDate[1]
           
        const whs: CSOption[] | undefined = (filters.find(filter => filter.id === "warehouses")?.value as CSOption[]) ?? [];
        const provisioningWhsIds: string[] = whs.map(option =>option.value )
        const provisioningId : string | undefined = sku?.value ?? ''
        const params = {
          pageIndex, 
          pageSize: 20,
          itemId: itemID,
          dateFrom: dateFrom?.toISOString(),
          dateTo: dateTo?.toISOString(),
          whsIds : provisioningWhsIds
        }
        
        const response:AxiosResponse<EntityListResponseWarehouseMovementViewSummary> = await axios.get('api/warehouses/movements/listByFilter', {
            params
        })
        return response.data

    } catch(error:any) {
        checkResponseStatus(error)
        throw error
    }
}

const addMovement = async (whId : string, body : {}) => {
    try{
        const params = {
            whId : whId,
            body : body
        }
        const response = await axios.post('api/warehouses/movements/addMovement',{params})
        return response.data

    } catch(error:any) {
        throw error
    }
}



export {getWarehouses, getWarehousesMovementsByOrderIdSummary, getWarehousesMovementsByFilters, addMovement, createWarehouse,updateWarehouse }